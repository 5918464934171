import gsap from "gsap";
import { black, grey, red } from "../const/color";
import { pulseDuration } from "../utils/animation/pulseAnimation";
import Common from "./Common";

class PageNation {
  constructor() {}
  init() {
    this.setMouseAnimation();
  }
  setMouseAnimation() {
    const texts = document.querySelectorAll(".pageNation_button");
    const numbers = document.querySelectorAll(".pageNation_number");
    const arrows = document.querySelectorAll(".pageNation_button_arrow");
    const arrowInners = document.querySelectorAll(
      ".pageNation_button_arrow_bg"
    );
    texts.forEach((target, index) => {
      const arrow = arrows[index];
      const arrowInner = arrowInners[index];
      target.addEventListener("mouseenter", () => {
        if (Common.isTouchDevice) return;
        target.style.color = red;
      });
      target.addEventListener("mouseleave", () => {
        target.style.color = black;
      });
      target.addEventListener("click", () => {
        const start = () => {
          gsap.to(target, {
            color: red,
            duration: pulseDuration,
            ease: "pulse",
          });
          gsap.to(arrow, {
            backgroundColor: red,
            duration: pulseDuration,
            overwrite: true,
            ease: "pulse",
          });
        };
        arrowInner.classList.add("js--none");
        gsap.to(target, {
          color: black,
          duration: 0,
          onComplete: start,
        });
      });
    });
    numbers.forEach((target) => {
      target.addEventListener("mouseenter", () => {
        if (Common.isTouchDevice) return;
        target.style.color = red;
      });
      target.addEventListener("mouseleave", () => {
        target.style.color = grey;
      });
      target.addEventListener("click", () => {
        const start = () => {
          gsap.to(target, {
            color: red,
            duration: pulseDuration,
            ease: "pulse",
          });
        };
        gsap.to(target, {
          color: grey,
          duration: 0,
          onComplete: start,
        });
      });
    });
  }
}

export default new PageNation();
