import { gsap } from "gsap";
import Common from "../../components/Common";
import { pulseDuration } from "./pulseAnimation";

export const bunnerAnimation = (bunnerNode) => {
  const anchors = document.querySelectorAll(bunnerNode);
  anchors.forEach((anchor) => {
    anchor.addEventListener("mouseenter", () => {
      if (Common.isTouchDevice) return;
      anchor.style.opacity = 0.9;
    });
    anchor.addEventListener("mouseleave", () => {
      anchor.style.opacity = 1;
    });
    anchor.addEventListener("click", () => {
      const start = () => {
        gsap.to(anchor, {
          opacity: 1,
          duration: pulseDuration,
          ease: "pulse",
        });
      };
      gsap.to(anchor, {
        opacity: 0.9,
        duration: 0,
        overwrite: true,
        onComplete: start,
      });
    });
  });
};
