import gsap from "gsap";
import { defaultDuration, shortDuration } from "../../const/animation";
import { black, white } from "../../const/color";
import Common from "../../components/Common";
import { pulseDuration } from "./pulseAnimation";

export const wideListMouseAnmation = (parentNode) => {
  const parent = document.querySelector(parentNode);
  const bg = document.querySelector(`${parentNode} .wideList_bg`);
  const bgInner = document.querySelectorAll(`${parentNode} .wideList_bg_inner`);
  const widelits = document.querySelectorAll(`${parentNode} .wideList_anchor`);
  const fadeTexts = document.querySelectorAll(
    `${parentNode} .wideList_fadetext`
  );
  const arrows = document.querySelectorAll(`${parentNode}  .wideList_imgBox`);
  const titleDefaults = document.querySelectorAll(
    `${parentNode} .wideList_title`
  );
  const subs = document.querySelectorAll(`${parentNode} .wideList_sub`);

  parent.addEventListener("mouseenter", () => {
    if (Common.isTouchDevice) return;
    const start = () => {
      gsap.to(bgInner, {
        yPercent: 100,
        duration: shortDuration,
      });
    };
    gsap.to(bgInner, {
      yPercent: 0,
      duration: 0,
      overwrite: true,
      onComplete: start,
    });
  });
  parent.addEventListener("mouseleave", () => {
    gsap.to(bgInner, {
      yPercent: 200,
      duration: shortDuration,
    });
  });

  widelits.forEach((widelit, index) => {
    const arrow = arrows[index];
    const fadeText = fadeTexts[index];
    const titleDefault = titleDefaults[index];
    const sub = subs[index];

    widelit.addEventListener("mouseenter", () => {
      if (Common.isTouchDevice) return;
      fadeText.style.visibility = "visible";
      gsap.to(bg, {
        yPercent: index * 100,
        duration: defaultDuration,
        ease: "jet",
      });
      gsap.to(arrow, {
        xPercent: -50,
        duration: shortDuration,
        ease: "jet",
      });
      gsap.to(titleDefault, {
        color: white,
        duration: pulseDuration,
        ease: "pulse",
        overwrite: true,
      });
      gsap.to(sub, {
        color: white,
        duration: pulseDuration,
        ease: "pulse",
        overwrite: true,
      });
      gsap.to(fadeText, {
        color: white,
        duration: pulseDuration,
        ease: "pulse",
        overwrite: true,
      });
    });
    widelit.addEventListener("mouseleave", () => {
      fadeText.style.visibility = "hidden";
      gsap.to(titleDefault, {
        color: black,
        duration: 0,
        overwrite: true,
      });
      gsap.to(sub, {
        color: black,
        duration: 0,
        overwrite: true,
      });
      gsap.to(fadeText, {
        color: black,
        duration: 0,
        overwrite: true,
      });
      gsap.to(arrow, {
        xPercent: 0,
        duration: shortDuration,
        ease: "jet",
      });
    });
    widelit.addEventListener("click", () => {
      const start = () => {
        gsap.to(titleDefault, {
          color: black,
          duration: pulseDuration,
          ease: "pulse",
        });
        gsap.to(sub, {
          color: black,
          duration: pulseDuration,
          ease: "pulse",
        });
        gsap.to(fadeText, {
          color: black,
          duration: pulseDuration,
          ease: "pulse",
        });
      };
      gsap.to(titleDefault, {
        color: white,
        duration: 0,
        overwrite: true,
      });
      gsap.to(sub, {
        color: white,
        duration: 0,
        overwrite: true,
      });
      gsap.to(fadeText, {
        color: white,
        duration: 0,
        overwrite: true,
        onComplete: start,
      });
    });
  });
};
