import gsap from "gsap";
import { defaultDuration, shortDuration } from "../const/animation";
import { black, red } from "../const/color";
import { pulseDuration } from "../utils/animation/pulseAnimation";
import Common from "./Common";

class NewsList {
  constructor() {}
  init() {
    this.setMouseAnimation();
  }
  setMouseAnimation() {
    const setCard = () => {
      const anchors = document.querySelectorAll(".newsCard_anchor");
      const titles = document.querySelectorAll(".newsCard_title");
      const imgs = document.querySelectorAll(".newsCard_img");
      anchors.forEach((anchor, index) => {
        const title = titles[index];
        const img = imgs[index];

        anchor.addEventListener("mouseenter", () => {
          if (Common.isTouchDevice) return;
          gsap.to(img, {
            transform: "scale(0.972, 0.956)",
            duration: defaultDuration,
            ease: "jet",
          });
          gsap.to(title, {
            color: red,
            duration: 0,
          });
        });
        anchor.addEventListener("mouseleave", () => {
          gsap.to(img, {
            transform: "scale(1, 1)",
            duration: defaultDuration,
            ease: "jet",
          });
          gsap.to(title, {
            color: black,
            duration: 0,
          });
        });
        anchor.addEventListener("click", () => {
          const start = () => {
            gsap.to(title, {
              color: red,
              duration: pulseDuration,
              ease: "pulse",
            });
          };
          gsap.to(title, {
            color: black,
            duration: 0,
            overwrite: true,
            onComplete: start,
          });
        });
      });
    };

    const setTag = () => {
      const tags = document.querySelectorAll(".newsCard_tag_anchor");
      tags.forEach((tag) => {
        tag.addEventListener("mouseenter", () => {
          if (Common.isTouchDevice) return;
          gsap.to(tag, {
            opacity: "0.75",
            duration: defaultDuration,
            ease: "jet",
          });
        });
        tag.addEventListener("mouseleave", () => {
          gsap.to(tag, {
            opacity: "1",
            duration: defaultDuration,
            ease: "jet",
          });
        });
        tag.addEventListener("click", () => {
          const start = () => {
            gsap.to(tag, {
              opacity: "0.75",
              duration: pulseDuration,
              ease: "pulse",
            });
          };
          gsap.to(tag, {
            opacity: "1",
            duration: 0,
            overwrite: true,
            onComplete: start,
          });
        });
      });
    };

    setCard();
    setTag();
  }
}

export default new NewsList();
