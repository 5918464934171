import gsap from "gsap";
import { black, red } from "../../const/color";
import { setTagAnimation } from "../../utils/animation/buttonAnimation";
import { inviewWideList } from "../../utils/animation/inViewAnimation";
import { pulseDuration } from "../../utils/animation/pulseAnimation";
import { wideListMouseAnmation } from "../../utils/animation/wideListAnimation.js";
import Common from "../Common";

class ArticlePage {
  constructor() {}
  init() {
    const node = ".articlePage .backtoTop";
    inviewWideList(node);
    wideListMouseAnmation(node);
    setTagAnimation();

    const anchors = document.querySelectorAll(".body a");

    anchors.forEach((anchor) => {
      anchor.addEventListener("mouseenter", () => {
        if (Common.isTouchDevice) return;
        anchor.style.color = red;
        anchor.style.borderBottomColor = red;
      });
      anchor.addEventListener("mouseleave", () => {
        anchor.style.color = black;
        anchor.style.borderBottomColor = black;
      });
      anchor.addEventListener("click", () => {
        const start = () => {
          gsap.to(anchor, {
            color: red,
            borderBottomColor: red,
            duration: pulseDuration,
            ease: "pulse",
          });
        };
        gsap.to(anchor, {
          color: black,
          borderBottomColor: black,
          duration: 0,
          onComplete: start,
        });
      });
    });
  }
}

export default new ArticlePage();
