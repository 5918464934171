import gsap from "gsap";
import { defaultDuration, shortDuration } from "../const/animation";
import { black, red } from "../const/color";
import { pulseDuration } from "../utils/animation/pulseAnimation";
import Common from "./Common";

class StaffList {
  constructor() {}
  init() {
    this.setMouseEvent();
  }
  setMouseEvent() {
    const setCard = () => {
      const targets = document.querySelectorAll(".staffCard_anchor");
      const imgs = document.querySelectorAll(
        ".staffCard_anchor .staffCard_img_bg"
      );
      const titles = document.querySelectorAll(
        ".staffCard_anchor .staffCard_name"
      );
      const triangles = document.querySelectorAll(
        ".staffCard_anchor .triangle"
      );
      targets.forEach((target, index) => {
        const img = imgs[index];
        const title = titles[index];
        const triangle = triangles[index];

        target.addEventListener("mouseenter", () => {
          if (Common.isTouchDevice) return;
          const start = () => {
            gsap.to(triangle, {
              opacity: 1,
              duration: pulseDuration,
              ease: "pulse",
            });
            gsap.to(img, {
              opacity: 0,
              duration: pulseDuration,
              ease: "pulse",
            });
          };
          gsap.to(img, {
            opacity: 1,
            duration: 0,
            overwrite: true,
          });
          gsap.to(triangle, {
            opacity: 0,
            duration: 0,
            overwrite: true,
            onComplete: start,
          });
          title.style.color = red;
        });
        target.addEventListener("mouseleave", () => {
          gsap.to(img, {
            opacity: 0,
            duration: 0,
            overwrite: true,
          });
          title.style.color = black;
        });
        target.addEventListener("click", () => {
          const start = () => {
            gsap.to(title, {
              color: red,
              duration: pulseDuration,
              ease: "pulse",
            });
          };
          gsap.to(title, {
            color: black,
            duration: 0,
            overwrite: true,
            onComplete: start,
          });
        });
      });
    };
    const setTag = () => {
      const targets = document.querySelectorAll(".staffList .js--tag");
      targets.forEach((target) => {
        target.addEventListener("mouseenter", () => {
          if (Common.isTouchDevice) return;
          gsap.to(target, {
            opacity: 0.75,
            duration: shortDuration,
            ease: "jet",
          });
        });
        target.addEventListener("mouseleave", () => {
          gsap.to(target, {
            opacity: 1,
            duration: shortDuration,
            ease: "jet",
          });
        });
        target.addEventListener("click", () => {
          const start = () => {
            gsap.to(target, {
              opacity: 0.75,
              duration: pulseDuration,
              ease: "pulse",
            });
          };
          gsap.to(target, {
            opacity: 1,
            duration: 0,
            overwrite: true,
            onComplete: start,
          });
        });
      });
    };
    setCard();
    setTag();
  }
}

export default new StaffList();
