import gsap from "gsap";
import { defaultDuration, shortDuration } from "../../const/animation";

export const fadeInBorder = (border, delay = shortDuration) => {
  gsap.to(border, {
    transform: "translate3d(0, 0, 0)",
    duration: defaultDuration,
    ease: "jet",
    delay: delay,
  });
};
