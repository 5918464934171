import gsap from "gsap";
import { defaultDuration, longDuration } from "../../const/animation";
import { black, grey, red, white } from "../../const/color";
import Common from "../../components/Common";
import {
  fadeInPulseAnimation,
  mouseEnterPulseAnimation,
  mouseLeavePulseAnimation,
  pulseDuration,
} from "./pulseAnimation";

export const strokeButtonMouseAnimation = () => {
  const buttons = document.querySelectorAll(".strokeButton");
  const bgs = document.querySelectorAll(".strokeButton_bg");
  const bgInners = document.querySelectorAll(".strokeButton_bg_inner");
  const texts = document.querySelectorAll(".strokeButton_text");
  const hideArrows = document.querySelectorAll(
    ".strokeButton_arrowInner.--white"
  );
  const showArrows = document.querySelectorAll(
    ".strokeButton_arrowInner.--black"
  );

  buttons.forEach((button, index) => {
    const bg = bgs[index];
    const bgInner = bgInners[index];
    const text = texts[index];
    const hideArrow = hideArrows[index];
    const showArrow = showArrows[index];
    button.addEventListener("mouseenter", () => {
      if (Common.isTouchDevice) return;
      bgInner.style.animationPlayState = "running";
      bg.style.visibility = "visible";
      gsap.to(text, {
        color: white,
        duration: pulseDuration,
        ease: "pulse",
      });
      gsap.to(hideArrow, {
        opacity: 1,
        duration: pulseDuration,
        ease: "pulse",
      });
      gsap.to(showArrow, {
        opacity: 0,
        duration: pulseDuration,
        ease: "pulse",
      });
    });
    button.addEventListener("mouseleave", () => {
      bgInner.style.animationPlayState = "paused";
      bg.style.visibility = "hidden";
      gsap.to(hideArrow, {
        opacity: 0,
        duration: 0,
        overwrite: true,
      });
      gsap.to(showArrow, {
        opacity: 1,
        duration: 0,
        overwrite: true,
      });
      gsap.to(text, {
        color: black,
        duration: 0,
        overwrite: true,
      });
    });
    button.addEventListener("click", () => {
      const start = () => {
        gsap.to(hideArrow, {
          opacity: 0,
          duration: pulseDuration,
          ease: "pulse",
        });
        gsap.to(showArrow, {
          opacity: 1,
          duration: pulseDuration,
          ease: "pulse",
        });
        gsap.to(text, {
          color: black,
          duration: pulseDuration,
          ease: "pulse",
        });
      };
      bgInner.style.animationPlayState = "running";
      bg.style.visibility = "visible";
      gsap.to(hideArrow, {
        opacity: 1,
        duration: 0,
        overwrite: true,
      });
      gsap.to(showArrow, {
        opacity: 0,
        duration: 0,
        overwrite: true,
      });
      gsap.to(text, {
        color: white,
        duration: 0,
        overwrite: true,
        onComplete: start,
      });
    });
  });
};

export const noBgButtonMouseAnimation = () => {
  const buttons = document.querySelectorAll(".noBgButton");
  const jpTextsPc = document.querySelectorAll(".noBgButton_text.--pc.--jp");
  const jpTextsSp = document.querySelectorAll(".noBgButton_text.--sp.--jp");
  const enTexts = document.querySelectorAll(".noBgButton_text.--en");
  const triangles = document.querySelectorAll(".noBgButton_triangle");
  const arrows = document.querySelectorAll(".noBgButton_arrow");
  const arrowInners = document.querySelectorAll(".noBgButton_arrow_inner");

  buttons.forEach((button, index) => {
    const jpTextPc = jpTextsPc[index];
    const jpTextSp = jpTextsSp[index];
    const enText = enTexts[index];
    const triangle = triangles[index];
    const arrow = arrows[index];
    const arrowInner = arrowInners[index];

    button.addEventListener("mouseenter", () => {
      if (Common.isTouchDevice) return;
      mouseEnterPulseAnimation(triangle);
      gsap.to(jpTextPc, {
        color: red,
        duration: 0,
      });
      gsap.to(jpTextSp, {
        color: red,
        duration: 0,
      });
      gsap.to(enText, {
        color: red,
        duration: 0,
      });
      gsap.to(arrow, {
        backgroundColor: grey,
        duration: 0,
      });
    });
    button.addEventListener("mouseleave", () => {
      gsap.to(jpTextPc, {
        color: black,
        duration: 0,
      });
      gsap.to(jpTextSp, {
        color: black,
        duration: 0,
      });
      gsap.to(enText, {
        color: black,
        duration: 0,
      });
      gsap.to(arrow, {
        backgroundColor: black,
        duration: 0,
      });
    });
    button.addEventListener("click", () => {
      const start = () => {
        gsap.to(jpTextPc, {
          color: black,
          duration: pulseDuration,
          ease: "pulse",
        });
        gsap.to(jpTextSp, {
          color: black,
          duration: pulseDuration,
          ease: "pulse",
        });
        gsap.to(enText, {
          color: black,
          duration: pulseDuration,
          ease: "pulse",
        });
        gsap.to(arrow, {
          backgroundColor: black,
          duration: pulseDuration,
          ease: "pulse",
        });
      };
      arrowInner.classList.add("js--none");
      gsap.to(jpTextPc, {
        color: red,
        duration: 0,
        overwrite: true,
      });
      gsap.to(jpTextSp, {
        color: red,
        duration: 0,
        overwrite: true,
      });
      gsap.to(arrow, {
        backgroundColor: red,
        duration: 0,
        overwrite: true,
      });
      gsap.to(enText, {
        color: red,
        duration: 0,
        overwrite: true,
        onComplete: start,
      });
    });
  });
};

export const buttonMouseAnimation = () => {
  const buttons = document.querySelectorAll(".fillButton");
  const bgs = document.querySelectorAll(".fillButton_bg");
  const texts = document.querySelectorAll(".fillButton_texts.--show");

  buttons.forEach((button, index) => {
    const bg = bgs[index];
    const text = texts[index];
    button.addEventListener("mouseenter", () => {
      if (Common.isTouchDevice) return;
      bg.style.animationPlayState = "running";
      bg.style.visibility = "visible";
      gsap.to(text, {
        opacity: 0,
        duration: 0,
        overwrite: true,
      });
      fadeInPulseAnimation(text);
    });
    button.addEventListener("mouseleave", () => {
      bg.style.visibility = "hidden";
      bg.style.animationPlayState = "paused";
    });
    button.addEventListener("click", () => {
      gsap.to(text, {
        opacity: 0,
        duration: 0,
        overwrite: true,
      });
      fadeInPulseAnimation(text);
    });
  });
};

export const buttonMouseAnimationCustomNode = (
  buttonsNode,
  bgsNode,
  textsNode
) => {
  const buttons = document.querySelectorAll(buttonsNode);
  const bgs = document.querySelectorAll(bgsNode);
  const texts = document.querySelectorAll(textsNode);

  buttons.forEach((button, index) => {
    const bg = bgs[index];
    const text = texts[index];
    button.addEventListener("mouseenter", () => {
      if (Common.isTouchDevice) return;
      bg.style.animationPlayState = "running";
      bg.style.visibility = "visible";
      gsap.to(text, {
        opacity: 0,
        duration: 0,
        overwrite: true,
      });
      fadeInPulseAnimation(text);
    });
    button.addEventListener("mouseleave", () => {
      bg.style.visibility = "hidden";
      bg.style.animationPlayState = "paused";
    });
    button.addEventListener("click", () => {
      gsap.to(text, {
        opacity: 0,
        duration: 0,
        overwrite: true,
      });
      fadeInPulseAnimation(text);
    });
  });
};

export const onlyArrowButtonEnter = (arrow) => {
  if (Common.isTouchDevice) return;
  mouseEnterPulseAnimation(arrow);
};
export const onlyArrowButtonLeave = (arrow) => {
  if (Common.isTouchDevice) return;
  mouseLeavePulseAnimation(arrow);
};

export const withClipPathArrowButtonEnter = (container, arrow) => {
  if (Common.isTouchDevice) return;
  const start = () => {
    gsap.to(container, {
      color: red,
      duration: longDuration,
      ease: "jet",
    });
    gsap.to(arrow, {
      xPercent: 100,
      duration: defaultDuration,
      ease: "jet",
      overwrite: true,
    });
  };
  gsap.to(arrow, {
    xPercent: 0,
    duration: 0,
    ease: "jet",
    overwrite: true,
    onComplete: start,
  });
};
export const withClipPathArrowButtonLeave = (container, arrow) => {
  if (Common.isTouchDevice) return;
  gsap.to(container, {
    color: black,
    duration: longDuration,
    ease: "jet",
  });
  gsap.to(arrow, {
    xPercent: 200,
    duration: defaultDuration,
    ease: "jet",
  });
};

export const strolkeArrowButtonEnter = (
  button,
  bg,
  text,
  arrow,
  arrowWhite,
  arrowBlack
) => {
  if (Common.isTouchDevice) return;

  gsap.to(button, {
    color: white,
    duration: longDuration,
    ease: "jet",
  });
  gsap.to(bg, {
    opacity: 1,
    duration: longDuration,
    ease: "jet",
  });
  gsap.to(text, {
    scale: 0.97,
    duration: longDuration,
    ease: "jet",
  });
  gsap.to(arrow, {
    xPercent: -15,
    duration: longDuration,
    ease: "jet",
  });
  gsap.to(arrowWhite, {
    opacity: 1,
    duration: longDuration,
    ease: "jet",
  });
  gsap.to(arrowBlack, {
    opacity: 0,
    duration: longDuration,
    ease: "jet",
  });
};
export const strolkeArrowButtonLeave = (
  button,
  bg,
  text,
  arrow,
  arrowWhite,
  arrowBlack
) => {
  if (Common.isTouchDevice) return;
  gsap.to(button, {
    color: black,
    duration: longDuration,
    ease: "jet",
  });
  gsap.to(bg, {
    opacity: 0,
    duration: longDuration,
    ease: "jet",
  });
  gsap.to(text, {
    scale: 1,
    duration: longDuration,
    ease: "jet",
  });
  gsap.to(arrow, {
    xPercent: 0,
    duration: longDuration,
    ease: "jet",
  });
  gsap.to(arrowWhite, {
    opacity: 0,
    duration: longDuration,
    ease: "jet",
  });
  gsap.to(arrowBlack, {
    opacity: 1,
    duration: longDuration,
    ease: "jet",
  });
};

export const setTagAnimation = () => {
  const tags = document.querySelectorAll(".js--tag");
  tags.forEach((tag) => {
    tag.addEventListener("mouseenter", () => {
      if (Common.isTouchDevice) return;
      tag.style.opacity = ".75";
    });
    tag.addEventListener("mouseleave", () => {
      tag.style.opacity = "1";
    });
    tag.addEventListener("click", () => {
      const start = () => {
        gsap.to(tag, {
          opacity: 1,
          duration: pulseDuration,
          ease: "pulse",
        });
      };
      gsap.to(tag, {
        opacity: 0,
        duration: 0,
        overwrite: true,
        onComplete: start,
      });
    });
  });
};
