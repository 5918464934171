import gsap from "gsap";
import {
  defaultDuration,
  longerDuration,
  shortDuration,
  shorterDuration,
} from "../../const/animation";
import { wait } from "../wait";
import { tileInt } from "./tileAnimation";

export const imgDuration = defaultDuration;
export const slideInImg = (img, screen) => {
  wait(0)
    .then(() => {
      gsap.to(screen, {
        xPercent: 100,
        duration: shortDuration,
        ease: "jet",
      });
      return wait(shortDuration);
    })
    .then(() => {
      const start = () => {
        gsap.to(img, {
          scale: 1,
          duration: longerDuration,
          ease: "jet",
        });
        gsap.to(screen, {
          xPercent: 200,
          duration: shorterDuration,
          ease: "jet",
        });
      };
      gsap.to(img, {
        opacity: 1,
        duration: 0,
        onComplete: start,
      });
    });
};

export const slideInImgs = (imgs, screens, interval = tileInt) => {
  if (imgs.length !== screens.length)
    console.error("引き痛の数を確認してください");

  imgs.forEach((img, index) => {
    const screen = screens[index];
    setTimeout(() => {
      slideInImg(img, screen);
    }, interval * index * 1000);
  });
};
