import gsap from "gsap";
import { defaultEaseInOut, shortDuration } from "../../const/animation";
import { spView } from "../../const/breakPoint";
import { black, red } from "../../const/color";
import { fadeInBorder } from "../../utils/animation/borderAnimation";
import { fadeInPulseAnimation } from "../../utils/animation/pulseAnimation";
import {
  fadeInTileAnimation,
  fadeInTileAnimations,
} from "../../utils/animation/tileAnimation";
import { getWinSize } from "../../utils/getWinSIze";
import Common from "../Common";

class MethodPage {
  constructor() {
    this.accordionArr = [];
  }
  init() {
    this.setStyle();
    this.setClickEvent();
    // this.setScrollEvent();
    this.setMouseEvent();
    this.setInviewAnimation();
  }
  setMouseEvent() {
    const accordionButtons = document.querySelectorAll(
      ".flow_flow_item_button"
    );
    accordionButtons.forEach((button) => {
      button.addEventListener("mouseenter", () => {
        if (Common.isTouchDevice) return;
        gsap.to(button, {
          color: red,
          duration: 0,
        });
      });
      button.addEventListener("mouseleave", () => {
        if (Common.isTouchDevice) return;
        gsap.to(button, {
          color: black,
          duration: 0,
        });
      });
    });
  }
  setStyle() {
    const flowCardNumbers = document.querySelectorAll(".flow_flow_item_number");
    flowCardNumbers.forEach((number, index) => {
      const zeroPaddingNum =
        index + 1 <= 10 ? `0${index + 1}` : (index + 1).toString();
      number.innerHTML = zeroPaddingNum;
      this.accordionArr.push(false);
    });
  }
  setAccordion() {
    const texts = document.querySelectorAll(".flow_flow_item_text");
    const containers = document.querySelectorAll(
      ".flow_flow_item_textContainer"
    );
    const buttons = document.querySelectorAll(".flow_flow_item_button");
    const gradations = document.querySelectorAll(".flow_flow_item_gradation");
    const iconLines = document.querySelectorAll(
      ".flow_flow_item_button_icon_line.--vert"
    );

    const showTexts = document.querySelectorAll(
      ".flow_flow_item_button_text.--show"
    );
    const hideTexts = document.querySelectorAll(
      ".flow_flow_item_button_text.--hide"
    );

    const open = (index) => {
      const text = texts[index];
      const container = containers[index];
      const gradation = gradations[index];
      const line = iconLines[index];
      const h = text.clientHeight;
      gsap.to(container, {
        height: `${h}px`,
        duration: 0,
        ease: defaultEaseInOut,
      });
      gsap.to(gradation, {
        opacity: 0,
        duration: 0,
        ease: defaultEaseInOut,
      });
      gsap.to(line, {
        opacity: 0,
        duration: 0,
        ease: defaultEaseInOut,
      });

      showTexts[index].style.opacity = "0";
      fadeInPulseAnimation(hideTexts[index]);
    };

    const close = (index) => {
      const container = containers[index];
      const gradation = gradations[index];
      const line = iconLines[index];
      const winSize = getWinSize();
      const h = winSize <= spView ? "155rem" : "112rem";

      gsap.to(container, {
        height: h,
        duration: 0,
        ease: defaultEaseInOut,
      });
      gsap.to(gradation, {
        opacity: 1,
        duration: 0,
        ease: defaultEaseInOut,
      });
      gsap.to(line, {
        opacity: 1,
        duration: 0,
        ease: defaultEaseInOut,
      });

      fadeInPulseAnimation(showTexts[index]);
      hideTexts[index].style.opacity = "0";
    };

    buttons.forEach((button, index) => {
      const textH = texts[index].clientHeight;
      const containerH = containers[index].clientHeight;

      if (textH < containerH) {
        button.classList.add("js--none");
        gradations[index].classList.add("js--none");
      }

      button.addEventListener("click", () => {
        this.accordionArr[index] = !this.accordionArr[index];

        this.accordionArr[index] ? open(index) : close(index);
      });
    });
  }
  setClickEvent() {
    this.setAccordion();
  }
  resize() {
    const texts = document.querySelectorAll(".flow_flow_item_text");
    const containers = document.querySelectorAll(
      ".flow_flow_item_textContainer"
    );
    const buttons = document.querySelectorAll(".flow_flow_item_button");
    const gradations = document.querySelectorAll(".flow_flow_item_gradation");

    containers.forEach((container, index) => {
      const textH = texts[index].clientHeight;
      const containerH = containers[index].clientHeight;

      if (textH >= containerH) {
        buttons[index].classList.remove("js--none");
        gradations[index].classList.remove("js--none");
      } else {
        buttons[index].classList.add("js--none");
        gradations[index].classList.add("js--none");
      }

      if (this.accordionArr[index]) {
        const text = texts[index];
        const h = text.clientHeight;
        gsap.to(container, {
          height: `${h}px`,
          duration: 0,
        });
      }
    });
  }
  setInviewAnimation() {
    const inView = (anchor, texts, borders, titles, triangle) => {
      const options = {
        root: null,
        rootMargin: "-20% 0px",
        threshold: 0,
      };
      const start = (entry) => {
        if (entry[0].isIntersecting) {
          fadeInTileAnimations(texts);
          borders.forEach((border) => {
            fadeInBorder(border);
          });
          titles.forEach((title) => {
            fadeInTileAnimation(title);
          });
          setTimeout(() => {
            fadeInPulseAnimation(triangle);
          }, shortDuration * 1000);
          observer.unobserve(entry[0].target);
        }
      };
      const observer = new IntersectionObserver(start, options);
      observer.observe(anchor);
    };

    const about = document.querySelector(".about");
    const aboutHeadLineTexts = document.querySelectorAll(
      ".about_headline_text_span"
    );
    const aboutBorders = document.querySelectorAll(".about .redLine_container");
    const aboutTitles = document.querySelectorAll(".about_title_text");
    const aboutTriangle = document.querySelector(
      ".about_title_triangle .triangle"
    );
    inView(about, aboutHeadLineTexts, aboutBorders, aboutTitles, aboutTriangle);

    const flow = document.querySelector(".flow");
    const flowHeadLineTexts = document.querySelectorAll(
      ".flow_headline_text_span"
    );
    const flowBorders = document.querySelectorAll(".flow .redLine_container");
    const flowTitles = document.querySelectorAll(".flow_title_text");
    const flowTriangle = document.querySelector(
      ".flow_title_triangle .triangle"
    );
    inView(flow, flowHeadLineTexts, flowBorders, flowTitles, flowTriangle);
  }
  setScrollEvent() {
    const counter = document.querySelector(".scrollCounter");
    const footerCta = document.querySelector(".footerCta");
    const hide = () => {
      gsap.to(counter, {
        opacity: 0,
        duration: 0,
        ease: defaultEaseInOut,
      });
    };

    const toggleFade = (entry) => {
      entry[0].isIntersecting ? hide() : fadeInTileAnimation(counter);
    };

    const options = {
      root: null,
      rootMargin: "50% 0px",
      threshold: 0,
    };

    const observer = new IntersectionObserver(toggleFade, options);
    observer.observe(footerCta);

    // const page = document.querySelector("#__pageWrapper");
    // const about = document.querySelector(".about");
    // const flow = document.querySelector(".flow");
    // window.addEventListener("scroll", () => {
    // const pageH = page.getBoundingClientRect().height;
    // const scrollTop = page.getBoundingClientRect().top * -1;
    // const aboutDeg = about.getBoundingClientRect().top;
    // console.log(aboutDeg);
    // });
  }
}

export default new MethodPage();
