import gsap from "gsap";
import { spView } from "../../const/breakPoint";
import { black, red } from "../../const/color";
import {
  fadeInPulseAnimation,
  pulseDuration,
} from "../../utils/animation/pulseAnimation";
import { getWinSize } from "../../utils/getWinSIze";
import Common from "../Common";
import { sideArrowButtonAnimation } from "../../utils/animation/sideArrowButtonAnimation";

class ContactPage {
  constructor() {}
  init() {
    this.setMouseAnimation();
  }
  setMouseAnimation() {
    const setInfoLink = () => {
      const targets = document.querySelectorAll(".store_infoLink");
      const botders = document.querySelectorAll(".store_infoLink_border");

      targets.forEach((target, index) => {
        const border = botders[index];
        target.addEventListener("mouseenter", () => {
          if (Common.isTouchDevice) return;
          target.style.color = red;
          border.style.backgroundColor = red;
        });
        target.addEventListener("mouseleave", () => {
          target.style.color = black;
          border.style.backgroundColor = black;
        });
        target.addEventListener("click", () => {
          const start = () => {
            gsap.to(target, {
              color: black,
              duration: pulseDuration,
              ease: "pulse",
            });
            gsap.to(border, {
              backgroundColor: black,
              duration: pulseDuration,
              ease: "pulse",
            });
          };
          gsap.to(target, {
            color: red,
            duration: 0,
            overwrite: true,
          });
          gsap.to(border, {
            backgroundColor: red,
            duration: 0,
            overwrite: true,
            onComplete: start,
          });
        });
      });
    };

    setInfoLink();
    sideArrowButtonAnimation(
      ".oteherContact_link",
      ".oteherContact_link_bg",
      ".oteherContact_link_arrow.--right",
      ".oteherContact_link_arrow.--left",
      ".oteherContact_link_text",
      2500,
      1000
    );
  }
}

export default new ContactPage();
