import { gsap } from "gsap";
import { shorterDuration } from "../const/animation";
import { roundNum } from "../utils/roundNum";

class ParallaxBg {
  constructor() {}
  init() {
    // const innerH = 150;
    const transRate = 90;
    const body = document.querySelector("body");
    const bgs = document.querySelectorAll(".parallaxBg");
    const inners = document.querySelectorAll(".parallaxBg_inner");
    const flags = [...Array(bgs.length)].map(() => {
      return false;
    });

    bgs.forEach((bg, index) => {
      const judgeIsWithIn = (entry) => {
        flags[index] = entry[0].isIntersecting;
      };
      const observer = new IntersectionObserver(judgeIsWithIn);
      observer.observe(bg);
    });

    const palalax = (index) => {
      const bg = bgs[index];
      const inner = inners[index];
      const rect = bg.getBoundingClientRect();
      const bodyRect = body.getBoundingClientRect();
      const winH = bodyRect.height;
      const height = rect.height;
      const top = rect.top;

      const deg = 1 - (winH + top) / (winH + height);
      const roundDeg =
        deg >= 1 ? 1 : deg <= 0 ? 0 : Math.round(deg * 100) / 100;

      const transY = roundDeg * transRate * -1;

      gsap.to(inner, {
        yPercent: transY,
        duration: shorterDuration,
        ease: "Power1.easeOut",
      });
    };

    window.addEventListener("scroll", () => {
      flags.forEach((flag, index) => {
        if (flag) palalax(index);
      });
    });

    flags.forEach((_, index) => {
      palalax(index);
    });
  }
}

export default new ParallaxBg();
