import { gsap } from "gsap";
import { bunnerAnimation } from "../../utils/animation/bunnerAnimation";
import { sideArrowButtonAnimation } from "../../utils/animation/sideArrowButtonAnimation";
import Common from "../Common";
import PageFooter from "../PageFooter";
import StaffList from "../StaffList";
import { pulseDuration } from "../../utils/animation/pulseAnimation";
import { black, red } from "../../const/color";
import FaqList from "../FaqList";

class LocationDetailsPage {
  constructor() {}
  init() {
    PageFooter.init();
    StaffList.init();
    FaqList.init();
    this.setLicenseAnimation();
    this.setAccessAnimation();
    this.setFlowAnimation();
    sideArrowButtonAnimation(
      ".campaign_button",
      ".campaign_button_bg",
      ".campaign_button_triangle.--right",
      ".campaign_button_triangle.--left",
      ".campaign_button_text",
      5000,
      1000
    );
    sideArrowButtonAnimation(
      ".dietCourse_button",
      ".dietCourse_button_bg",
      ".dietCourse_button_triangle.--right",
      ".dietCourse_button_triangle.--left",
      ".dietCourse_button_text",
      500,
      500
    );
    bunnerAnimation(".campaign_card");
  }
  setLicenseAnimation() {
    let isOpen = false;
    const anchor = document.querySelector(".license_button");
    const lineHori = document.querySelector(".license_button_line.--vert");
    const text = document.querySelector(".license_button_text");
    const detail = document.querySelector(".license_details");
    anchor.addEventListener("mouseenter", () => {
      if (Common.isTouchDevice) return;
      const start = () => {
        gsap.to(text, {
          opacity: 1,
          duration: pulseDuration,
          ease: "pulse",
        });
      };
      gsap.to(text, {
        opacity: 0,
        duration: 0,
        overwrite: true,
        onComplete: start,
      });
    });
    anchor.addEventListener("mouseleave", () => {
      gsap.to(text, {
        opacity: 1,
        duration: 0,
        overwrite: true,
      });
    });
    anchor.addEventListener("click", () => {
      if (!isOpen) {
        lineHori.style.opacity = 0;
        detail.classList.remove("js--none");
        gsap.to(detail, {
          opacity: 1,
          duration: pulseDuration,
          ease: "pulse",
        });
        isOpen = true;
      } else {
        lineHori.style.opacity = 1;
        gsap.to(detail, {
          opacity: 0,
          duration: 0,
          overwrite: true,
        });
        detail.classList.add("js--none");
        isOpen = false;
      }
    });
  }
  setAccessAnimation() {
    const anchors = document.querySelectorAll(".flow_anchor");
    const triangles = document.querySelectorAll(".flow_anchor .triangle");
    anchors.forEach((anchor, index) => {
      const triangle = triangles[index];
      anchor.addEventListener("mouseenter", () => {
        if (Common.isTouchDevice) return;
        const start = () => {
          gsap.to(triangle, {
            opacity: 1,
            duration: pulseDuration,
            ease: "pulse",
          });
        };
        gsap.to(triangle, {
          opacity: 0,
          duration: 0,
          overwrite: true,
          onComplete: start,
        });
        anchor.style.color = red;
      });
      anchor.addEventListener("mouseleave", () => {
        gsap.to(triangle, {
          opacity: 1,
          duration: 0,
          overwrite: true,
        });
        anchor.style.color = black;
      });
    });
  }
  setFlowAnimation() {
    const anchors = document.querySelectorAll(".access_mapAnchor");
    const triangles = document.querySelectorAll(".access_mapAnchor .triangle");
    anchors.forEach((anchor, index) => {
      const triangle = triangles[index];
      anchor.addEventListener("mouseenter", () => {
        if (Common.isTouchDevice) return;
        const start = () => {
          gsap.to(triangle, {
            opacity: 1,
            duration: pulseDuration,
            ease: "pulse",
          });
        };
        gsap.to(triangle, {
          opacity: 0,
          duration: 0,
          overwrite: true,
          onComplete: start,
        });
        anchor.style.color = red;
      });
      anchor.addEventListener("mouseleave", () => {
        gsap.to(triangle, {
          opacity: 1,
          duration: 0,
          overwrite: true,
        });
        anchor.style.color = black;
      });
    });
  }
}

export default new LocationDetailsPage();
