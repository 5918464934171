import gsap from "gsap";
import { defaultDuration } from "../const/animation";
import { red, white } from "../const/color";
import { pulseDuration } from "../utils/animation/pulseAnimation";
import Common from "./Common";

class Search {
  constructor() {}
  init() {
    this.setMouseEvent();
  }
  setMouseEvent() {
    const searchs = document.querySelectorAll(".search_item");
    const buttons = document.querySelectorAll(".search_head");
    const bodys = document.querySelectorAll(".search_body");
    const texts = document.querySelectorAll(".search_head_text");
    const lines = document.querySelectorAll(".search_head_lineInner.--vert");
    const items = document.querySelectorAll(".search_body_menu");
    const openArr = [...Array(buttons.length)].map(() => {
      return false;
    });
    searchs.forEach((search, index) => {
      const text = texts[index];
      search.addEventListener("mouseenter", () => {
        if (Common.isTouchDevice || openArr[index]) return;
        const start = () => {
          gsap.to(text, {
            opacity: 1,
            duration: pulseDuration,
            ease: "pulse",
          });
        };
        gsap.to(text, {
          opacity: 0,
          overwrite: true,
          duration: 0,
          onComplete: start,
        });
      });
    });
    buttons.forEach((button, index) => {
      const body = bodys[index];
      const line = lines[index];
      const item = items[index];
      button.addEventListener("click", () => {
        if (openArr[index]) {
          body.classList.remove("--open");
          openArr[index] = false;
          line.style.display = "block";
          gsap.to(item, {
            opacity: 0,
            duration: 0,
            overwrite: true,
          });
        } else {
          body.classList.add("--open");
          openArr[index] = true;
          line.style.display = "none";
          const start = () => {
            gsap.to(item, {
              opacity: 1,
              duration: pulseDuration,
              ease: "pulse",
            });
          };
          gsap.to(item, {
            opacity: 0,
            duration: 0,
            overwrite: true,
            onComplete: start,
          });
        }
      });
    });

    const anchors = document.querySelectorAll(".search_body_anchor");
    anchors.forEach((anchor) => {
      anchor.addEventListener("mouseenter", () => {
        if (Common.isTouchDevice) return;
        anchor.style.color = red;
      });
      anchor.addEventListener("mouseleave", () => {
        anchor.style.color = white;
      });
      anchor.addEventListener("click", () => {
        const start = () => {
          gsap.to(anchor, {
            color: white,
            duration: pulseDuration,
            ease: "pulse",
          });
        };
        gsap.to(anchor, {
          color: red,
          duration: 0,
          overwrite: true,
          onComplete: start,
        });
      });
    });
  }
}

export default new Search();
