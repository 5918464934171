import PageFooter from "../PageFooter";

class ReviewDetailsPage {
  constructor() {}
  init() {
    PageFooter.init();
  }
}

export default new ReviewDetailsPage();
