import gsap from "gsap";
import {
  defaultDuration,
  longDuration,
  shortDuration,
} from "../const/animation";
import { black, red, white } from "../const/color";
import Common from "./Common";
import { fadeInTileAnimations } from "../utils/animation/tileAnimation";
import { wait } from "../utils/wait";
import {
  mouseEnterPulseAnimation,
  pulseDuration,
} from "../utils/animation/pulseAnimation";

class Header {
  constructor() {
    this.isSpMenuOpen = false;
    this.isLogoOnBlackElm = false;
  }

  // bundle
  init() {
    this.setStyle();
    this.setEvent();
  }
  setEvent() {
    this.setHoverAnimation();
    this.setClickEvent();
    this.setScrollEvent();
  }

  // resizeEvent
  setStyle() {
    if (document.querySelector(".methodPage")) {
      const target = document.querySelector(".headerMenuCardPc.--toMethod");
      target.classList.add("js--noClick", "js--current");
    } else if (document.querySelector(".pricePage")) {
      const target = document.querySelector(".headerMenuCardPc.--toPrice");
      target.classList.add("js--noClick", "js--current");
    } else if (document.querySelector(".reviewPage")) {
      const target = document.querySelector(".headerMenuCardPc.--toReview");
      target.classList.add("js--noClick", "js--current");
    } else if (document.querySelector(".locationPage")) {
      const target = document.querySelector(".headerMenuCardPc.--toLocation");
      target.classList.add("js--noClick", "js--current");
    } else if (document.querySelector(".staffPage")) {
      const target = document.querySelector(".headerMenuCardPc.--toStaff");
      target.classList.add("js--noClick", "js--current");
    } else if (document.querySelector(".mediaPage")) {
      const target = document.querySelector(".headerMenuCardPc.--toMedia");
      target.classList.add("js--noClick", "js--current");
    } else if (document.querySelector(".faqPage")) {
      const target = document.querySelector(".headerMenuCardPc.--toFaq");
      target.classList.add("js--noClick", "js--current");
    } else if (document.querySelector(".trainingIndexPage")) {
      const target = document.querySelector(".headerMenuCardPc.--toTraining");
      target.classList.add("js--noClick", "js--current");
    }
  }

  // mouseEvent
  setHoverAnimation() {
    const menuLists = document.querySelectorAll(".headerMenuCardPc_anchor");
    const menuListPoints = document.querySelectorAll(
      ".headerMenuCardPc_selectPoint"
    );
    const menuListPointInners = document.querySelectorAll(
      ".headerMenuCardPc_selectPoint_inner"
    );
    const menuListTexts = document.querySelectorAll(".headerMenuCardPc_text");
    menuLists.forEach((list, index) => {
      list.addEventListener("mouseenter", () => {
        if (Common.isTouchDevice) return;
        gsap.to(menuListPoints[index], {
          backgroundColor: red,
          opacity: 1,
          duration: pulseDuration,
          ease: "pulse",
        });
        gsap.to(menuListTexts[index], {
          color: red,
          duration: 0,
        });
        gsap.to(menuListPointInners[index], {
          backgroundColor: red,
          duration: 0,
        });
      });
      list.addEventListener("mouseleave", () => {
        gsap.to(menuListPoints[index], {
          backgroundColor: black,
          opacity: 0,
          duration: 0,
          overwrite: true,
        });
        gsap.to(menuListTexts[index], {
          color: black,
          duration: 0,
          overwrite: true,
        });
        gsap.to(menuListPointInners[index], {
          backgroundColor: black,
          duration: 0,
          overwrite: true,
        });
      });
      list.addEventListener("click", () => {
        const start = () => {
          gsap.to(menuListTexts[index], {
            color: black,
            duration: pulseDuration,
            ease: "pulse",
            overwrite: true,
          });
          gsap.to(menuListPointInners[index], {
            backgroundColor: black,
            duration: pulseDuration,
            ease: "pulse",
            overwrite: true,
          });
        };
        gsap.to(menuListTexts[index], {
          color: red,
          duration: 0,
          overwrite: true,
        });
        gsap.to(menuListPointInners[index], {
          backgroundColor: red,
          duration: 0,
          overwrite: true,
          onComplete: start,
        });
      });
    });

    const spMenuButton = document.querySelector(".headerMenuSp_button");
    const spMenuCircleClose = document.querySelector(
      ".headerMenuSp_button_circle.--close"
    );
    const spMenuCircleOpen = document.querySelector(
      ".headerMenuSp_button_circle.--open"
    );
    spMenuButton.addEventListener("mouseenter", () => {
      if (Common.isTouchDevice) return;
      mouseEnterPulseAnimation(spMenuCircleClose);
      mouseEnterPulseAnimation(spMenuCircleOpen);
    });
  }

  // clickEvent
  setClickEvent() {
    const isMedia = document.querySelector(".mediaPage");

    const button = document.querySelector(".headerMenuSp_button");
    const screen = document.querySelector(".headerMenuSp_screen");
    const openText = document.querySelector(".headerMenuSp_button_item.--open");
    const whiteLogo = document.querySelector(".headerMenuSp_Logo");
    const closeText = document.querySelector(
      ".headerMenuSp_button_item.--close"
    );
    const list = isMedia
      ? document.querySelector(".headerMenuSp_list.--media")
      : document.querySelector(".headerMenuSp_list.--default");
    const listItems = isMedia
      ? document.querySelectorAll(
          ".headerMenuSp_list.--media .headerMenuSp_item"
        )
      : document.querySelectorAll(
          ".headerMenuSp_list.--default .headerMenuSp_item"
        );

    const openSpMenu = () => {
      gsap.to(screen, {
        opacity: 0.5,
        pointerEvents: "auto",
        duration: defaultDuration,
        ease: "jet",
      });
      gsap.to(whiteLogo, {
        opacity: 1,
        pointerEvents: "auto",
        duration: 0,
        ease: "jet",
      });
      const start = () => {
        gsap.to(list, {
          opacity: 1,
          xPercent: 0,
          pointerEvents: "auto",
          duration: defaultDuration,
          ease: "jet",
        });
      };
      gsap.to(list, {
        opacity: 0,
        xPercent: 10,
        duration: 0,
        overwrite: true,
        onComplete: start,
      });

      const startText = () => {
        gsap.to(openText, {
          opacity: 1,
          xPercent: 0,
          pointerEvents: "auto",
          duration: defaultDuration,
          ease: "jet",
        });
      };
      gsap.to(openText, {
        opacity: 0,
        xPercent: 10,
        duration: 0,
        overwrite: true,
        onComplete: startText,
      });
      gsap.to(closeText, {
        opacity: 0,
        xPercent: -10,
        duration: defaultDuration,
        ease: "jet",
      });

      listItems.forEach((listItem) => {
        listItem.style.opacity = 0;
      });
      fadeInTileAnimations(listItems);
    };

    const closeSpMenu = () => {
      gsap.to(whiteLogo, {
        opacity: 0,
        pointerEvents: "none",
        duration: 0,
        ease: "jet",
      });
      gsap.to(screen, {
        opacity: 0,
        pointerEvents: "none",
        duration: defaultDuration,
        ease: "jet",
      });
      gsap.to(list, {
        opacity: 0,
        xPercent: 10,
        pointerEvents: "none",
        duration: defaultDuration,
        ease: "jet",
      });

      const startText = () => {
        gsap.to(closeText, {
          opacity: 1,
          xPercent: 0,
          pointerEvents: "none",
          duration: defaultDuration,
          ease: "jet",
        });
      };
      gsap.to(closeText, {
        opacity: 0,
        xPercent: -10,
        duration: 0,
        overwrite: true,
        onComplete: startText,
      });
      gsap.to(openText, {
        opacity: 0,
        xPercent: 10,
        duration: defaultDuration,
        ease: "jet",
      });

      listItems.forEach((listItem) => {
        gsap.to(listItem, {
          opacity: 0,
          duration: 0,
        });
      });
    };

    button.addEventListener("click", () => {
      this.isSpMenuOpen ? closeSpMenu() : openSpMenu();
      this.isSpMenuOpen = !this.isSpMenuOpen;
    });

    const arrowInners = document.querySelectorAll(".headerMenuSp_arrow_inner");
    const arrows = document.querySelectorAll(".headerMenuSp_arrow");
    listItems.forEach((listItem, index) => {
      const arrowInner = arrowInners[index];
      const arrow = arrows[index];
      listItem.addEventListener("click", () => {
        const start = () => {
          gsap.to(listItem, {
            opacity: 0,
            duration: pulseDuration,
            ease: "pulse",
          });
          gsap.to(arrow, {
            opacity: 0,
            duration: pulseDuration,
            ease: "pulse",
            overwrite: true,
          });
        };
        gsap.to(arrow, {
          backgroundColor: white,
          duration: 0,
          overwrite: true,
        });
        gsap.to(listItem, {
          opacity: 1,
          duration: 0,
          overwrite: true,
          onComplete: start,
        });
        arrowInner.classList.add("js--none");
      });
    });
  }

  // scrollEvent
  setScrollEvent() {
    const footer = document.querySelector(".footer");
    const blackBgSections = document.querySelectorAll(".js--blackBg");
    const logoBox = document.querySelector(".headerLogo");
    const logoBlack = document.querySelector(".headerLogo_img.--black");
    const logoWhite = document.querySelector(".headerLogo_img.--white");
    const menuText = document.querySelector(
      ".headerMenuSp_button_text.--close"
    );
    const menuCircle = document.querySelector(
      ".headerMenuSp_button_circle.--close"
    );
    const boolArr = [...Array(blackBgSections.length)].map(() => false);

    // ロゴが黒バックの要素に重なったときに色を反転させる
    const toggleLogo = () => {
      if (this.isLogoOnBlackElm) {
        gsap.to(logoWhite, {
          opacity: 1,
          duration: longDuration,
          ease: "jet",
        });
        gsap.to(logoBlack, {
          opacity: 0,
          duration: longDuration,
          ease: "jet",
        });
        gsap.to(menuText, {
          color: white,
          duration: longDuration,
          ease: "jet",
        });
        gsap.to(menuCircle, {
          backgroundColor: white,
          duration: longDuration,
          ease: "jet",
        });
      } else {
        gsap.to(logoWhite, {
          opacity: 0,
          duration: longDuration,
          ease: "jet",
        });
        gsap.to(logoBlack, {
          opacity: 1,
          duration: longDuration,
          ease: "jet",
        });
        gsap.to(menuText, {
          color: black,
          duration: longDuration,
          ease: "jet",
        });
        gsap.to(menuCircle, {
          backgroundColor: black,
          duration: longDuration,
          ease: "jet",
        });
      }
    };

    // ロゴがfooter要素に重なったときにfadeさせる
    let onFooter = false;
    const fadeLogo = (bool) => {
      if (bool) {
        gsap.to(logoBox, {
          xPercent: -10,
          opacity: 0,
          duration: shortDuration,
          ease: "jet",
        });
      } else {
        gsap.to(logoBox, {
          xPercent: 0,
          opacity: 1,
          duration: shortDuration,
          ease: "jet",
        });
      }
    };

    window.addEventListener("scroll", () => {
      const logoRect = logoBox.getBoundingClientRect();
      blackBgSections.forEach((target, index) => {
        const targetRect = target.getBoundingClientRect();
        const isTargetOnLogo =
          targetRect.top < logoRect.top + logoRect.height &&
          targetRect.bottom > logoRect.top;
        boolArr[index] = isTargetOnLogo;
      });

      const footerRect = footer.getBoundingClientRect();
      const isfooterOnLogo =
        footerRect.top < logoRect.top + logoRect.height * 10 &&
        footerRect.bottom > logoRect.top;

      if (onFooter !== isfooterOnLogo) {
        fadeLogo(isfooterOnLogo);
        onFooter = isfooterOnLogo;
      }

      const isTargetOnLogo = boolArr.includes(true);
      if (this.isLogoOnBlackElm !== isTargetOnLogo) {
        this.isLogoOnBlackElm = isTargetOnLogo;
        toggleLogo();
      }
    });

    // 画面がスクロールされた際にmenuの背景を表示する処理の実装
    const watcher = document.querySelector(".scrollOvserverWatcher");
    const menuBg = document.querySelector(".headerMenuPc_bg");
    const toggleMenuBg = (entries) => {
      const opacity = entries[0].isIntersecting ? 0 : 1;
      gsap.to(menuBg, {
        opacity: opacity,
        duration: defaultDuration,
        ease: "jet",
      });
    };
    const options = {
      root: null,
      rootMargin: "40% 0px",
      threshold: 0,
    };
    const observer = new IntersectionObserver(toggleMenuBg, options);
    observer.observe(watcher);
  }

  enter1() {
    if (document.querySelector(".mediaPage")) {
      const pcList = document.querySelector(".headerMenuListPc.--media");
      pcList.classList.remove("js--none");
      const spList = document.querySelector(".headerMenuSp_list.--media");
      spList.classList.remove("js--none");
      const headerTexts = document.querySelectorAll(
        ".headerMenuListPc.--media .headerMenuCardPc_itemBox"
      );
      fadeInTileAnimations(headerTexts);
    } else {
      const pcList = document.querySelector(".headerMenuListPc.--default");
      pcList.classList.remove("js--none");
      const spList = document.querySelector(".headerMenuSp_list.--default");
      spList.classList.remove("js--none");
      const headerTexts = document.querySelectorAll(
        ".headerMenuListPc.--default .headerMenuCardPc_itemBox"
      );
      fadeInTileAnimations(headerTexts);
    }

    const header = document.querySelector(".header_container");
    gsap.to(header, {
      opacity: 1,
      duration: longDuration,
      ease: "jet",
    });
  }

  enterForFirstSession() {
    const duration = defaultDuration * 2;
    wait(duration).then(() => {
      this.enter1();
    });
  }
  enter() {
    this.enter1();
  }
}

export default new Header();
