import NewsList from "../NewsList";
import PageNation from "../PageNation";
import Search from "../Search";

class CampaignPage {
  constructor() {}
  init() {
    PageNation.init();
    NewsList.init();
    Search.init();
  }
}

export default new CampaignPage();
