import { gsap } from "gsap";
import Common from "../../components/Common";
import { getWinSize } from "../getWinSIze";
import { spView } from "../../const/breakPoint";
import { fadeInPulseAnimation, pulseDuration } from "./pulseAnimation";

export const sideArrowButtonAnimation = (
  anchorsNode,
  bgsNode,
  rightArrowsNode,
  leftArrowsode,
  textsNode,
  pcDist = 0,
  spDist = 0
) => {
  const targets = document.querySelectorAll(anchorsNode);
  const bgs = document.querySelectorAll(bgsNode);
  const rightArrows = document.querySelectorAll(rightArrowsNode);
  const leftArrows = document.querySelectorAll(leftArrowsode);
  const texts = document.querySelectorAll(textsNode);
  targets.forEach((target, index) => {
    const rightArrow = rightArrows[index];
    const leftArrow = leftArrows[index];
    const text = texts[index];
    const bg = bgs[index];
    target.addEventListener("mouseenter", () => {
      if (Common.isTouchDevice) return;
      bg.style.animationPlayState = "running";
      bg.style.visibility = "visible";
      const winSize = getWinSize();
      const dist = winSize <= spView ? spDist : pcDist;
      gsap.to(rightArrow, {
        xPercent: -dist,
        duration: pulseDuration,
        ease: "jet",
      });
      gsap.to(leftArrow, {
        xPercent: dist,
        duration: pulseDuration,
        ease: "jet",
      });
      gsap.to(text, {
        opacity: 0,
        duration: 0,
        overwrite: true,
      });
      fadeInPulseAnimation(text);
    });
    target.addEventListener("mouseleave", () => {
      gsap.to(rightArrow, {
        xPercent: 0,
        duration: pulseDuration,
        ease: "jet",
      });
      gsap.to(leftArrow, {
        xPercent: 0,
        duration: pulseDuration,
        ease: "jet",
      });
      bg.style.visibility = "hidden";
      bg.style.animationPlayState = "paused";
    });
    target.addEventListener("click", () => {
      gsap.to(text, {
        opacity: 0,
        duration: 0,
        overwrite: true,
      });
      fadeInPulseAnimation(text);
    });
  });
};
