import { inteviewAnimation } from "../../utils/animation/interviewAnimation";
import PageNation from "../PageNation";

class ReviewPage {
  constructor() {}
  init() {
    PageNation.init();
    inteviewAnimation(
      ".interview .interview_anchor",
      ".interview .interview_arrow",
      ".interview .interview_triangle",
      ".interview .interview_arrowInner"
    );
    inteviewAnimation(
      ".reviews .reviews_anchor",
      ".reviews .reviews_arrow",
      ".reviews .triangle",
      ".reviews .reviews_arrowInner"
    );
  }
}

export default new ReviewPage();
