// rule
// 要素を段階的に出現させるアニメーションの中で、共通化できるものを格納する

import gsap from "gsap";
import { defaultDuration, shorterDuration } from "../../const/animation";
import { arrayShuffle } from "../arrayShuffle";
import { fadeInBorder } from "./borderAnimation";

export const tileInt = defaultDuration * 0.03;
export const tileDuration = shorterDuration;

export const fadeInFirstViewText = (texts) => {
  const shuffleArr = [
    0, 1, 7, 4, 9, 3, 8, 5, 12, 14, 11, 15, 2, 6, 17, 10, 16, 13,
  ];
  texts.forEach((_, index) => {
    setTimeout(() => {
      gsap.to(texts[shuffleArr[index]], {
        opacity: 1,
        duration: tileDuration,
        ease: "pulse",
      });
    }, tileInt * index * 1000);
  });
};

export const fadeInTileAnimations = (
  targets,
  interval = tileInt,
  duration = tileDuration,
  ease = "pulse"
) => {
  const arr = [...Array(targets.length)].map((_, index) => index);
  const shuffleArr = arrayShuffle(arr);
  targets.forEach((_, index) => {
    setTimeout(() => {
      gsap.to(targets[shuffleArr[index]], {
        opacity: 1,
        duration: duration,
        ease: ease,
      });
    }, interval * index * 1000);
  });
};

export const fadeOutTileAnimations = (
  targets,
  interval = tileInt,
  duration = tileDuration,
  ease = "pulse"
) => {
  const arr = [...Array(targets.length)].map((_, index) => index);
  const shuffleArr = arrayShuffle(arr);

  targets.forEach((_, index) => {
    setTimeout(() => {
      gsap.to(targets[shuffleArr[index]], {
        opacity: 0,
        duration: duration,
        ease: ease,
      });
    }, interval * index * 1000);
  });
};

export const fadeInTriangleAnimation = (target) => {
  if (!target) return;
  gsap.to(target, {
    opacity: 1,
    duration: defaultDuration,
    ease: "pulse",
    overwrite: true,
  });
};

export const fadeOutTriangleAnimation = (target) => {
  if (!target) return;
  gsap.to(target, {
    opacity: 0,
    duration: defaultDuration,
    ease: "pulse",
  });
};

export const fadeInTileAnimation = (
  target,
  duration = tileDuration,
  ease = "pulse"
) => {
  if (!target) return;
  gsap.to(target, {
    opacity: 1,
    duration: duration,
    ease: ease,
  });
};
export const fadeOutTileAnimation = (target) => {
  if (!target) return;
  gsap.to(target, {
    opacity: 0,
    duration: tileDuration,
    ease: "pulse",
  });
};

export const inViewTileAnimation = (monitoredClassName) => {
  const monitored = document.querySelector(monitoredClassName);
  const animationTargets = document.querySelectorAll(
    `${monitoredClassName} .js--tileAnimationElm`
  );

  const callBack = (entry) => {
    if (entry[0].isIntersecting) {
      const arr = [...Array(animationTargets.length)].map((_, index) => index);
      const shuffleArr = arrayShuffle(arr);
      animationTargets.forEach((_, index) => {
        setTimeout(() => {
          const target = animationTargets[shuffleArr[index]];
          gsap.to(target, {
            opacity: 1,
            duration: tileDuration,
            ease: "pulse",
          });
        }, tileInt * index * 1000);
      });
      observer.unobserve(entry[0].target);
    }
  };
  const options = {
    root: null,
    rootMargin: "-20% 0px",
    threshold: 0,
  };
  const observer = new IntersectionObserver(callBack, options);
  observer.observe(monitored);
};

export const inViewTileLines = (monitoredClassName) => {
  const monitored = document.querySelector(monitoredClassName);
  if (!monitored) return;

  const animationTargets = document.querySelectorAll(
    `${monitoredClassName} .redLine.js--noInview .redLine_container`
  );
  const callBack = (entry) => {
    if (entry[0].isIntersecting) {
      animationTargets.forEach((target, index) => {
        setTimeout(() => {
          fadeInBorder(target);
        }, tileInt * index * 1000);
      });
      observer.unobserve(entry[0].target);
    }
  };
  const options = {
    root: null,
    rootMargin: "-20% 0px",
    threshold: 0,
  };
  const observer = new IntersectionObserver(callBack, options);
  observer.observe(monitored);
};
