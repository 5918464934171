import { gsap } from "gsap";
import { mostLongestDuration, shortDuration } from "../const/animation";
import { inViewTileLines } from "../utils/animation/tileAnimation";
import { sideArrowButtonAnimation } from "../utils/animation/sideArrowButtonAnimation";

class PageFooter {
  constructor() {}
  init() {
    this.setInviewAnimation();
    sideArrowButtonAnimation(
      ".pageFooter_button",
      ".pageFooter_button_bg",
      ".pageFooter_button_triangle.--right",
      ".pageFooter_button_triangle.--left",
      ".pageFooter_button_text",
      2500,
      800
    );
  }
  setInviewAnimation() {
    const footer = document.querySelector(".pageFooter");
    const screenTop = document.querySelector(".pageFooter_screen.--top");
    const screenBottom = document.querySelector(".pageFooter_screen.--bottom");
    const screenLeft = document.querySelector(".pageFooter_screen.--left");
    const screenRight = document.querySelector(".pageFooter_screen.--right");
    const imgPc = document.querySelector(".pageFooter_img.--pc");
    const imgSp = document.querySelector(".pageFooter_img.--sp");
    const options = {
      root: null,
      rootMargin: "-50% 0px",
      threshold: 0,
    };
    const start = (entry) => {
      if (entry[0].isIntersecting) {
        gsap.to(screenTop, {
          transform: "scale(1, 0)",
          duration: shortDuration,
          ease: "jet",
        });
        gsap.to(screenBottom, {
          transform: "scale(1, 0)",
          duration: shortDuration,
          ease: "jet",
        });
        gsap.to(screenLeft, {
          transform: "scale(0, 1)",
          duration: shortDuration,
          ease: "jet",
        });
        gsap.to(screenRight, {
          transform: "scale(0, 1)",
          duration: shortDuration,
          ease: "jet",
        });
        gsap.to(imgPc, {
          transform: "scale(1, 1)",
          duration: mostLongestDuration,
          ease: "jet",
        });
        gsap.to(imgSp, {
          transform: "scale(1, 1)",
          duration: mostLongestDuration,
          ease: "jet",
        });
        observer.unobserve(entry[0].target);
      }
    };
    const observer = new IntersectionObserver(start, options);
    observer.observe(footer);
  }
}

export default new PageFooter();
