import gsap from "gsap";
import {
  defaultDuration,
  longDuration,
  longerDuration,
  mostLongestDuration,
  shortDuration,
  shorterDuration,
} from "../const/animation";
import { spView } from "../const/breakPoint";
import {
  fadeInFirstViewText,
  fadeInTileAnimation,
  fadeInTileAnimations,
  fadeInTriangleAnimation,
} from "../utils/animation/tileAnimation";
import { getWinSize } from "../utils/getWinSIze";
import { wait } from "../utils/wait";

class Firstview {
  constructor() {
    this.fadeArr_12_12 = [
      [0],
      [1, 13, 24],
      [12, 3, 14, 4, 5, 17, 27, 26, 38, 37, 48, 60, 50],
      [
        72, 61, 49, 25, 2, 15, 28, 29, 52, 51, 7, 8, 18, 30, 40, 41, 63, 73, 84,
        96, 101, 139, 120, 32, 31,
      ],
      [
        80, 88, 35, 54, 42, 65, 75, 87, 97, 109, 121, 122, 134, 132, 110, 99,
        36, 6, 62, 74, 86, 98, 85, 16, 39, 77, 112, 9, 10, 108, 64, 20, 21, 22,
        43, 67,
      ],
      [
        11, 23, 33, 19, 44, 53, 66, 76, 135, 137, 114, 90, 76, 46, 58, 55, 68,
        78, 100, 111, 133, 71, 59, 47, 57, 69, 81, 70, 83, 103, 116,
      ],
      [
        34, 45, 56, 79, 89, 136, 123, 124, 142, 106, 94, 91, 102, 113, 125, 127,
        128, 119, 117,
      ],
      [92, 105, 82, 104, 115, 126, 138, 95, 118],
      [93, 107, 129, 140, 141, 130, 131],
      [143],
    ];

    this.total = 0;
    this.isRunning = false;
    this.current = 0;
    this.step = 0;
    this.threshold = 0;

    this.isShow = false;

    this.progress = 0;
    this.lastProgress = 0;
    this.startTime = 0;
    this.fpsRate = 1;
  }

  init() {
    this.setVariable();
    this.writeCarouselNumber();
    this.setStyle();
    window.addEventListener("focus", () => {
      if (this.isRunning === false && this.isShow) this.startSlide();
    });
    window.addEventListener("blur", () => {
      this.isRunning = false;
    });
  }

  // 変数設定
  setVariable() {
    const carouselItemNumbers = document.querySelectorAll(
      ".firstview_sliderPc_carousel_number"
    );
    this.total = carouselItemNumbers.length - 1;
    this.threshold = Math.round((100 / carouselItemNumbers.length) * 100) / 100;
  }

  // 文字書き換え系
  writeCarouselNumber() {
    // const carouselItemNumbers = document.querySelectorAll(
    //   ".firstview_sliderPc_carousel_number"
    // );
    // carouselItemNumbers.forEach((number, index) => {
    //   const zeroPaddingNumber = ("00" + (index + 1)).slice(-2);
    //   number.innerHTML = zeroPaddingNumber;
    // });
  }

  // リサイズ系
  resize() {
    this.setStyle();
  }
  setStylePc() {
    const carouselContainer = document.querySelector(
      ".firstview_sliderPc_carousel_container"
    );
    const carouselItems = document.querySelectorAll(
      ".firstview_sliderPc_carousel_item"
    );
    const carouselItemHeight =
      carouselContainer.clientHeight / carouselItems.length;
    carouselItems.forEach((item) => {
      item.style.height = `${carouselItemHeight}px`;
    });

    const tileImgsAnchor = document.querySelector(
      ".firstview_sliderPc .firstview_sliderPc_card"
    );
    const tiles = document.querySelectorAll(
      ".firstview_sliderPc .firstview_tileImgs_img"
    );
    tiles.forEach((tile) => {
      tile.style.height = `${tileImgsAnchor.clientHeight}px`;
    });
  }
  setStyleSp() {
    const carouselContainer = document.querySelector(
      ".firstview_sliderSp_carousel_container"
    );
    const carouselItems = document.querySelectorAll(
      ".firstview_sliderSp_carousel_item"
    );
    const carouselItemWidth =
      carouselContainer.clientWidth / carouselItems.length;
    carouselItems.forEach((item) => {
      item.style.width = `${carouselItemWidth}px`;
    });

    // const body = document.querySelector("body");
    // const carousel = document.querySelector(".firstview_sliderSp_carousel");
    // carousel.style.top = `calc(${body.clientHeight * 0.513}px - 30rem)`;

    const tileImgsAnchor = document.querySelector(
      ".firstview_sliderSp .firstview_sliderSp_card"
    );
    const tiles = document.querySelectorAll(
      ".firstview_sliderSp .firstview_tileImgs_img"
    );
    tiles.forEach((tile) => {
      tile.style.width = `${tileImgsAnchor.clientWidth}px`;
      tile.style.height = `${tileImgsAnchor.clientHeight}px`;
    });
  }
  setStyle() {
    const body = document.querySelector("body");
    const textBox = document.querySelector(".firstView_textBox");
    // const slider = document.querySelector(".firstview_sliderSp_window");
    const bodyH = body.getBoundingClientRect().height;
    const winSize = getWinSize();
    winSize > spView && `${bodyH}px`;
    if (winSize > spView) {
      textBox.style.minHeight = `${bodyH}px`;
    }
    // textBox.style.minHeight = `${bodyH}px`;
    this.setStylePc();
    this.setStyleSp();

    // const winSize = getWinSize();
    // winSize <= spView
    //   ? (textBox.style.paddingTop = `${bodyH * 0.513}px`)
    //   : (textBox.style.paddingTop = "60rem");

    // if (winSize <= spView) slider.style.height = `${bodyH * 0.513}px`;
  }

  // ページ遷移アニメーション
  enter() {
    this.enterPc();
    this.enterSp();
  }
  enterForFirstSession() {
    this.enterForFirstSessionPc();
    this.enterForFirstSessionSp();
  }
  enterPc1() {
    const sliderCards = document.querySelectorAll(".firstview_sliderPc_card");
    gsap.to(sliderCards[0], {
      opacity: 1,
      duration: defaultDuration,
      ease: "jet",
    });
  }
  enterPc2() {
    const container = document.querySelector(".firstview_sliderPc_container");
    const scaleContainer = document.querySelector(
      ".firstview_sliderPc_scaleContainer"
    );
    const sliderCoverLeft = document.querySelector(
      ".firstview_sliderPc_cover.--left"
    );
    const sliderCoverRight = document.querySelector(
      ".firstview_sliderPc_cover.--right"
    );
    const textBox = document.querySelector(".firstView_textBox");

    gsap.to(container, {
      x: 0,
      duration: defaultDuration,
      ease: "jet",
    });
    gsap.to(scaleContainer, {
      scale: 1,
      duration: defaultDuration,
      ease: "jet",
    });
    gsap.to(sliderCoverLeft, {
      scale: 1,
      duration: defaultDuration,
      ease: "jet",
    });
    gsap.to(sliderCoverRight, {
      scale: 1,
      duration: defaultDuration,
      ease: "jet",
    });

    const textInners = document.querySelectorAll(".firstView_catch_inner");
    textInners.forEach((inner) => {
      gsap.to(inner, {
        x: 0,
        duration: 0,
        ease: "jet",
      });
    });

    const sideTexts = document.querySelectorAll(
      ".firstview_sliderPc_carousel_message_text"
    );
    const scaleElmsPc = document.querySelectorAll(
      ".firstview_sliderPc_carousel .--firstview_carousel_fadeElm"
    );
    const scaleElmsSp = document.querySelectorAll(
      ".firstview_sliderSp_carousel .--firstview_carousel_fadeElm"
    );
    const trianglePc = document.querySelector(
      ".firstview_sliderPc_carousel .firstview_sliderPc_carousel_arrowBox"
    );
    const triangleSp = document.querySelector(
      ".firstview_sliderSp_carousel .firstview_sliderSp_carousel_arrow"
    );
    const cta = document.querySelector(".firstView_cta");

    const fadeInTileTexts = document.querySelectorAll(".firstView_catch_text");
    fadeInFirstViewText(fadeInTileTexts);
    fadeInTileAnimations(sideTexts);
    fadeInTileAnimations(scaleElmsPc);
    fadeInTileAnimations(scaleElmsSp);
    setTimeout(() => {
      fadeInTileAnimation(trianglePc, shortDuration);
      fadeInTileAnimation(triangleSp, shortDuration);
    }, defaultDuration * 1000);

    const winSize = getWinSize();
    if (winSize <= spView) {
      const start = () => {
        gsap.to(textBox, {
          yPercent: 0,
          opacity: 1,
          duration: longDuration,
          ease: "jet",
        });
      };
      const startCta = () => {
        gsap.to(cta, {
          yPercent: 0,
          opacity: 1,
          duration: longDuration,
          ease: "jet",
          delay: shorterDuration,
        });
      };
      gsap.to(textBox, {
        yPercent: 12,
        duration: 0,
        onComplete: start,
      });
      gsap.to(cta, {
        yPercent: 12,
        opacity: 0,
        duration: 0,
        onComplete: startCta,
      });
    } else {
      const start = () => {
        gsap.to(textBox, {
          xPercent: 0,
          opacity: 1,
          duration: longerDuration,
          ease: "jet",
        });
      };
      const startCta = () => {
        gsap.to(cta, {
          xPercent: 0,
          opacity: 1,
          duration: longerDuration,
          ease: "jet",
          delay: shorterDuration,
        });
      };
      gsap.to(textBox, {
        xPercent: -5,
        duration: 0,
        onComplete: start,
      });
      gsap.to(cta, {
        xPercent: -5,
        opacity: 0,
        duration: 0,
        onComplete: startCta,
      });
    }
  }
  afterEnterPc() {
    const slideWindow = document.querySelector(".firstview_sliderPc_window");
    slideWindow.classList.add("js--bgGrey");
    this.toggleSlideByScroll();
  }
  enterForFirstSessionPc() {
    const duration = defaultDuration;
    wait(duration)
      .then(() => {
        this.enterPc1();
        return wait(duration);
      })
      .then(() => {
        this.enterPc2();
        this.afterEnterPc();
      });
  }
  enterSp1() {
    const sliderCards = document.querySelectorAll(".firstview_sliderSp_card");
    gsap.to(sliderCards[0], {
      opacity: 1,
      duration: defaultDuration,
      ease: "jet",
    });
  }
  enterSp2() {
    const slider = document.querySelectorAll(".firstview_sliderSp_container");
    gsap.to(slider, {
      y: 0,
      scale: 1,
      duration: defaultDuration,
      ease: "jet",
    });
  }
  enterSp3() {
    const slideWindow = document.querySelector(".firstview_sliderSp_window");
    slideWindow.classList.add("js--bgGrey");
  }
  enterForFirstSessionSp() {
    const duration = defaultDuration;
    wait(duration)
      .then(() => {
        this.enterSp1();
        return wait(duration);
      })
      .then(() => {
        this.enterSp2();
        return wait(duration);
      })
      .then(() => {
        this.enterSp3();
      });
  }
  enterPc() {
    const duration = defaultDuration;
    wait(0)
      .then(() => {
        this.enterPc1();
        this.enterPc2();
        return wait(duration);
      })
      .then(() => {
        this.afterEnterPc();
      });
  }
  enterSp() {
    const duration = defaultDuration;
    wait(0)
      .then(() => {
        this.enterSp1();
        this.enterSp2();
        return wait(duration);
      })
      .then(() => {
        this.enterSp3();
      });
  }

  // スライダー系
  updateTimeRatio() {
    const lastTime = this.startTime;
    if (lastTime > 0) {
      const fps60Sec = 1000 / 60;
      const dTime = new Date().getTime() - lastTime;
      this.fpsRate = Math.round((dTime / fps60Sec) * 100) / 100;
    }
    this.startTime = new Date().getTime();
  }
  startSlide() {
    this.isRunning = true;
    this.startTime = 0;
    this.fpsRate = 1;
    const listPc = document.querySelector(".firstview_sliderPc_carousel_list");
    const itemPc = document.querySelector(".firstview_sliderPc_carousel_item");
    const progressBarPc = document.querySelector(
      ".firstview_sliderPc_carousel_progress.--front"
    );
    const listSp = document.querySelector(".firstview_sliderSp_carousel_list");
    const itemSp = document.querySelector(".firstview_sliderSp_carousel_item");
    const progressBarSp = document.querySelector(
      ".firstview_sliderSp_carousel_progress.--front"
    );
    const update = () => {
      this.updateTimeRatio();
      this.step += 0.003 * this.fpsRate;

      const winSize = getWinSize();
      if (winSize > spView) {
        const degreePc = Math.round(
          (itemPc.clientHeight / 100) * this.step * 100
        );
        const progressPc = degreePc / listPc.clientHeight;
        progressBarPc.style.transform = `scale(1, ${progressPc})`;
      } else {
        const degreeSp = Math.round(
          (itemSp.clientWidth / 100) * this.step * 100
        );
        const progressSp = degreeSp / listSp.clientWidth;
        progressBarSp.style.transform = `scale(${progressSp}, 1)`;
      }

      const current = Math.floor(this.step % (this.total + 1));
      if (this.current !== current) this.changeSlide();
      if (this.isRunning) window.requestAnimationFrame(update);
    };
    window.requestAnimationFrame(update);
  }

  changeSlide() {
    const before = this.current;
    this.current = this.current >= this.total ? 0 : this.current + 1;

    // タイル状の画像を段階的に消す
    const tileInt =
      ((defaultDuration - defaultDuration * 0.2) / this.fadeArr_12_12.length) *
      1000;
    const startTileAnimation = (tiles) => {
      const fadeOutTile = () => {
        this.fadeArr_12_12.forEach((item, index) => {
          setTimeout(() => {
            item.forEach((number) => {
              tiles[number].style.opacity = 0;
            });
          }, index * tileInt);
        });
      };
      fadeOutTile();
    };

    const change = (cards, tileBox, frontImg, triangles) => {
      const tileCount = tileBox[before].childElementCount;
      const tilesBofore = [...Array(tileCount)].map((_, index) => {
        return tileBox[before].children[index];
      });
      const tilesCurrent = [...Array(tileCount)].map((_, index) => {
        return tileBox[this.current].children[index];
      });

      wait(0)
        .then(() => {
          // 前準備
          frontImg[before].style.opacity = 0;
          cards[before].style.zIndex = 2;
          cards[this.current].style.zIndex = 0;
          cards[this.current].style.opacity = 1;
          frontImg[this.current].style.opacity = 1;

          tilesCurrent.forEach((tile) => {
            tile.style.opacity = 1;
          });

          return wait(0);
        })
        .then(() => {
          startTileAnimation(tilesBofore);
          const scale = () => {
            gsap.to(cards[this.current], {
              scale: 1,
              ease: "jet",
              duration: mostLongestDuration,
            });
          };
          gsap.to(cards[this.current], {
            scale: 1.1,
            duration: 0,
            overwrite: true,
            onComplete: scale,
          });
          triangles[before].style.opacity = 0;
          fadeInTriangleAnimation(triangles[this.current]);
        });
    };

    const tileBoxPc = document.querySelectorAll(
      ".firstview_sliderPc_card_inner"
    );
    const tileBoxSp = document.querySelectorAll(
      ".firstview_sliderSp_card_inner"
    );
    const frontImgsPc = document.querySelectorAll(
      ".firstview_sliderPc_frontImg"
    );
    const frontImgsSp = document.querySelectorAll(
      ".firstview_sliderSp_frontImg"
    );
    const cardsPc = document.querySelectorAll(".firstview_sliderPc_card");
    const cardsSp = document.querySelectorAll(".firstview_sliderSp_card");
    const trianglesPc = document.querySelectorAll(
      ".firstview_sliderPc_carousel_arrowBox"
    );
    const trianglesSp = document.querySelectorAll(
      ".firstview_sliderSp_carousel_arrow"
    );
    change(cardsPc, tileBoxPc, frontImgsPc, trianglesPc);
    change(cardsSp, tileBoxSp, frontImgsSp, trianglesSp);
    if (this.current === 0) this.step = 0;
  }
  toggleSlideByScroll() {
    const target = document.querySelector(".firstView");
    const start = (entry) => {
      entry[0].isIntersecting ? this.startSlide() : (this.isRunning = false);

      entry[0].isIntersecting ? (this.isShow = true) : (this.isShow = false);
    };
    const observer = new IntersectionObserver(start);
    observer.observe(target);
  }
}

export default new Firstview();
