import FaqList from "../FaqList";
import Search from "../Search";

class FaqPage {
  constructor() {}
  init() {
    Search.init();
    FaqList.init();
  }
}

export default new FaqPage();
