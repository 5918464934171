import gsap from "gsap";
import Footer from "./Footer";
import FooterCta from "./FooterCta";
import Header from "./Header";
import Loading from "./Loading";
import { defaultDuration } from "../const/animation";
import {
  buttonMouseAnimation,
  noBgButtonMouseAnimation,
  strokeButtonMouseAnimation,
} from "../utils/animation/buttonAnimation";
import {
  inViewBlackLines,
  inViewFooter,
  inViewImgBox,
  inVIewnoBgButton,
  inViewRedLines,
  inviewSctionHeader,
  inviewSctionHeaderNoBorder,
  inviewStorkeButton,
  inViewTriangles,
} from "../utils/animation/inViewAnimation";
import { enterPageHeader } from "../utils/animation/pageHederAnimation";
import { pulseDuration } from "../utils/animation/pulseAnimation";
import ParallaxBg from "./ParallaxBg";

const sessionStorageKey = "drTraining_isFirstSession";
const sessionStorageValue = "true";
class Common {
  constructor() {
    this.isDebugMode = false;
    this.isFirstSession = true;
    this.isTouchDevice = false;
  }
  setSessionStorage() {
    this.isFirstSession = sessionStorage.getItem(sessionStorageKey)
      ? false
      : true;

    !this.isFirstSession ||
      sessionStorage.setItem(sessionStorageKey, sessionStorageValue);
  }
  setJudgeIsTouchDevice() {
    const mediaQuery = window.matchMedia("(hover: none) and (pointer: coarse)");
    this.isTouchDevice = mediaQuery.matches;

    const handleMediaQueryChanged = (event) => {
      this.isTouchDevice = event.matches;
    };

    mediaQuery.addEventListener("change", handleMediaQueryChanged.bind(this));
  }
  setSmoothScroll() {
    const anchors = document.querySelectorAll('a[href^="#"]');

    for (let i = 0; i < anchors.length; i++) {
      anchors[i].addEventListener("click", (e) => {
        e.preventDefault();
        const href = anchors[i].getAttribute("href");
        const target = document.getElementById(href.replace("#", ""));

        if (!target) return;

        const position =
          window.pageYOffset + target.getBoundingClientRect().top;
        window.scroll({
          top: position,
          behavior: "smooth",
        });
      });
    }
  }
  // SearchCombined.jsでもページ離脱時のアニメーションを管理しているので、そちらと連動するように
  setLeaveAnimation() {
    const anohcors = document.querySelectorAll("a");
    const body = document.querySelector("body");
    anohcors.forEach((anohcor) => {
      const href = anohcor.getAttribute("href") || "/";
      const target = anohcor.getAttribute("target") || "";
      if (
        href.indexOf("#") === 0 ||
        target.indexOf("_blank") === 0 ||
        href.indexOf("tel:") === 0 ||
        href.indexOf("mailto:") === 0
      )
        return;

      anohcor.addEventListener("click", (e) => {
        e.preventDefault();

        if (href.indexOf("#") !== -1) return;
        const done = () => {
          window.location.href = href;
        };
        gsap.to(body, {
          opacity: 0,
          duration: pulseDuration,
          ease: "Power1.easeIn",
          onComplete: done,
        });
      });
    });
  }
  init() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    this.setSessionStorage();
    this.setJudgeIsTouchDevice();
    Loading.init();
    Header.init();
    FooterCta.init();
    Footer.init();
    this.setSmoothScroll();
    this.setLeaveAnimation();

    inViewRedLines();
    inViewBlackLines();
    inViewFooter();
    inVIewnoBgButton();
    inViewImgBox();
    inviewStorkeButton();
    inviewSctionHeader();
    inviewSctionHeaderNoBorder();
    inViewTriangles();

    buttonMouseAnimation();
    strokeButtonMouseAnimation();
    noBgButtonMouseAnimation();
    ParallaxBg.init();
  }
  enterForFirstSession() {
    Loading.enterForFirstSession();
    Header.enterForFirstSession();
    setTimeout(() => {
      enterPageHeader();
    }, defaultDuration * 2 * 1000);
  }
  enter() {
    Loading.enter();
    Header.enter();
    enterPageHeader();
  }
  resize() {
    Loading.resize();
  }
}

export default new Common();
