import { gsap } from "gsap";
import { pulseDuration } from "../utils/animation/pulseAnimation";
import Common from "./Common";
import { buttonMouseAnimationCustomNode } from "../utils/animation/buttonAnimation";

class SearchCombined {
  constructor() {}
  init() {
    this.setMouseEvent();
  }
  setMouseEvent() {
    const searchs = document.querySelectorAll(".searchCombined_item");
    const buttons = document.querySelectorAll(".searchCombined_head");
    const bodys = document.querySelectorAll(".searchCombined_body");
    const texts = document.querySelectorAll(".searchCombined_head_text");
    const lines = document.querySelectorAll(
      ".searchCombined_head_lineInner.--vert"
    );
    const items = document.querySelectorAll(".searchCombined_body_menu");
    const openArr = [...Array(buttons.length)].map(() => {
      return false;
    });

    // 開閉ボタンのマウスイベント周り
    searchs.forEach((search, index) => {
      const text = texts[index];
      search.addEventListener("mouseenter", () => {
        if (Common.isTouchDevice || openArr[index]) return;
        const start = () => {
          gsap.to(text, {
            opacity: 1,
            duration: pulseDuration,
            ease: "pulse",
          });
        };
        gsap.to(text, {
          opacity: 0,
          overwrite: true,
          duration: 0,
          onComplete: start,
        });
      });
    });

    // リストの開閉処理
    buttons.forEach((button, index) => {
      const body = bodys[index];
      const line = lines[index];
      const item = items[index];
      button.addEventListener("click", (e) => {
        e.preventDefault();
        if (openArr[index]) {
          body.classList.remove("--open");
          openArr[index] = false;
          line.style.display = "block";
          gsap.to(item, {
            opacity: 0,
            duration: 0,
            overwrite: true,
          });
        } else {
          body.classList.add("--open");
          openArr[index] = true;
          line.style.display = "none";
          const start = () => {
            gsap.to(item, {
              opacity: 1,
              duration: pulseDuration,
              ease: "pulse",
            });
          };
          gsap.to(item, {
            opacity: 0,
            duration: 0,
            overwrite: true,
            onComplete: start,
          });
        }
      });
    });

    // タグのマウスイベント周り
    const anchors = document.querySelectorAll(".searchCombined_body_anchor");
    const labels = document.querySelectorAll(".searchCombined_body_label");
    const anchorsArr = [...Array(anchors.length)].map(() => {
      return false;
    });
    anchors.forEach((anchor, index) => {
      anchor.addEventListener("mouseenter", () => {
        if (Common.isTouchDevice) return;
        anchor.classList.add("js--hover");
      });
      anchor.addEventListener("mouseleave", () => {
        anchor.classList.remove("js--hover");
      });
      const label = labels[index];
      label.addEventListener("click", () => {
        anchorsArr[index] = anchorsArr[index] ? false : true;
        anchorsArr[index]
          ? anchor.classList.add("js--focus")
          : anchor.classList.remove("js--focus");
      });
    });

    // 送信ボタンのマウスイベント周り
    buttonMouseAnimationCustomNode(
      ".searchCombined_submit_button",
      ".searchCombined_submit_bg",
      ".searchCombined_submit_text"
    );
    // 送信ボタンのクリックイベント周り
    const body = document.querySelector("body");
    const forms = document.querySelectorAll(".searchCombined_lists");
    const submitButtons = document.querySelectorAll(
      ".searchCombined_submit_button"
    );
    submitButtons.forEach((button, index) => {
      const form = forms[index];
      button.addEventListener("click", (e) => {
        e.preventDefault();
        const done = () => {
          form.submit();
        };
        gsap.to(body, {
          opacity: 0,
          duration: pulseDuration,
          ease: "Power1.easeIn",
          onComplete: done,
        });
      });
    });
  }
}

export default new SearchCombined();
