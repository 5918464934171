import { sideArrowButtonAnimation } from "../../utils/animation/sideArrowButtonAnimation";
import PageFooter from "../PageFooter";

class PricePage {
  constructor() {}
  init() {
    sideArrowButtonAnimation(
      ".priceSection_button",
      ".priceSection_button_bg",
      ".priceSection_button_triangle.--right",
      ".priceSection_button_triangle.--left",
      ".priceSection_button_text",
      300,
      100
    );
    PageFooter.init();
  }
}

export default new PricePage();
