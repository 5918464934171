import { gsap } from "gsap";
import { black, red, white } from "../../const/color";
import Common from "../Common";
import { pulseDuration } from "../../utils/animation/pulseAnimation";
import { getWinSize } from "../../utils/getWinSIze";
import { spView } from "../../const/breakPoint";
import { shortDuration } from "../../const/animation";
import { sideArrowButtonAnimation } from "../../utils/animation/sideArrowButtonAnimation";

class CompanyPage {
  constructor() {}
  init() {
    this.setSideMenuEvent();
    if (document.querySelector(".companyCorpPage")) this.setCta();
  }
  setCta() {
    sideArrowButtonAnimation(
      ".cta_button",
      ".cta_button_bg",
      ".cta_button_triangle.--right",
      ".cta_button_triangle.--left",
      ".cta_button_text",
      1000,
      0
    );
  }
  setSideMenuEvent() {
    const container = document.querySelector(".companyPage");

    const markCurrent = (anchorNode, targetNode) => {
      const targets = document.querySelectorAll(
        `.sideMenu_anchor${targetNode}`
      );
      if (container.classList.contains(anchorNode)) {
        targets.forEach((target) => {
          target.classList.add("js--current");
        });
      }
    };
    markCurrent("companyProfilePage", ".js--toAbout");
    markCurrent("companyCorpPage", ".js--toCorp");
    markCurrent("companyTrainerPage", ".js--toTrainer");
    markCurrent("companyGroupPage", ".js--toGroup");

    const anchorsPc = document.querySelectorAll(
      ".sideMenu.--pc .sideMenu_anchor"
    );
    const anchorsSp = document.querySelectorAll(
      ".sideMenu.--sp .sideMenu_anchor"
    );
    anchorsPc.forEach((anchor) => {
      anchor.addEventListener("mouseenter", () => {
        if (Common.isTouchDevice) return;
        gsap.to(anchor, {
          color: red,
          borderColor: red,
          duration: 0,
        });
      });
      anchor.addEventListener("mouseleave", () => {
        gsap.to(anchor, {
          color: black,
          borderColor: black,
          duration: 0,
        });
      });
      anchor.addEventListener("click", () => {
        const start = () => {
          gsap.to(anchor, {
            color: black,
            borderColor: black,
            duration: pulseDuration,
            ease: "pulse",
          });
        };
        gsap.to(anchor, {
          color: red,
          borderColor: red,
          duration: 0,
          overwrite: true,
          onComplete: start,
        });
      });
    });
    anchorsSp.forEach((anchor) => {
      anchor.addEventListener("mouseenter", () => {
        if (Common.isTouchDevice) return;
        gsap.to(anchor, {
          color: red,
          borderColor: red,
          duration: 0,
        });
      });
      anchor.addEventListener("mouseleave", () => {
        gsap.to(anchor, {
          color: white,
          borderColor: white,
          duration: 0,
        });
      });
      anchor.addEventListener("click", () => {
        const start = () => {
          gsap.to(anchor, {
            color: white,
            borderColor: white,
            duration: pulseDuration,
            ease: "pulse",
          });
        };
        gsap.to(anchor, {
          color: red,
          borderColor: red,
          duration: 0,
          onComplete: start,
        });
      });
    });
  }
}

export default new CompanyPage();
