import gsap from "gsap";
import { defaultDuration, shortDuration } from "../const/animation";
import { spView } from "../const/breakPoint";
import { black, grey, red } from "../const/color";
import { inViewFooterCta } from "../utils/animation/inViewAnimation";
import { pulseDuration } from "../utils/animation/pulseAnimation";
import { getWinSize } from "../utils/getWinSIze";
import Common from "./Common";

class FooterCta {
  constructor() {}
  init() {
    this.setHoverAnimation();
    inViewFooterCta();
  }
  setHoverAnimation() {
    const targets = document.querySelectorAll(".footerCta_anchor");
    const redLinesFronts = document.querySelectorAll(
      ".footerCta_redLine_inner.--one"
    );
    const redLinesBacks = document.querySelectorAll(
      ".footerCta_redLine_inner.--three"
    );
    const arrows = document.querySelectorAll(".footerCta_arrow");
    const arrowInners = document.querySelectorAll(".footerCta_arrowInner");
    const triangles = document.querySelectorAll(".footerCta_counterTriangle");
    const lineVerts = document.querySelectorAll(".footerCta_currentLine");
    const lineHori = document.querySelector(".footerCta_counterLine.--front");

    const duration = shortDuration;

    const fadeIn = (
      target,
      redLinesFront,
      redLinesBack,
      arrows,
      arrowInners,
      total,
      index
    ) => {
      const winSize = getWinSize();
      const delay = winSize <= spView ? 0 : duration;

      gsap.to(target, {
        color: red,
        duration: pulseDuration,
        ease: "pulse",
        delay: delay,
      });
      gsap.to(redLinesFront, {
        backgroundColor: red,
        duration: pulseDuration,
        ease: "pulse",
        delay: delay,
      });
      gsap.to(redLinesBack, {
        backgroundColor: red,
        duration: pulseDuration,
        ease: "pulse",
        delay: delay,
      });

      const startArrowInners = () => {
        gsap.to(arrowInners, {
          visibility: "visible",
          animationPlayState: "running",
          duration: 0,
        });
      };
      gsap.to(arrows, {
        backgroundColor: grey,
        duration: 0,
        delay: delay,
        onComplete: startArrowInners,
      });

      triangles.forEach((t, i) => {
        if (i === index) {
          gsap.to(lineVerts[i], {
            opacity: 1,
            duration: 0,
            overwrite: true,
            delay: delay,
          });
          gsap.to(t, {
            opacity: 1,
            duration: pulseDuration,
            ease: "pulse",
            delay: delay,
          });
        } else {
          gsap.to(t, {
            opacity: 0,
            duration: 0,
            overwrite: true,
          });
          gsap.to(lineVerts[i], {
            opacity: 0,
            duration: 0,
            overwrite: true,
          });
        }
      });

      const scaleX = index / total;
      gsap.to(lineHori, {
        transform: `scale(${scaleX}, 1)`,
        duration: duration,
        ease: "jet",
      });
    };

    const fadeOut = (
      target,
      redLinesFront,
      redLinesBack,
      arrow,
      arrowInners,
      triangle,
      lineVert
    ) => {
      gsap.to(target, {
        color: black,
        duration: 0,
        overwrite: true,
      });
      gsap.to(redLinesFront, {
        backgroundColor: black,
        duration: 0,
        overwrite: true,
      });
      gsap.to(redLinesBack, {
        backgroundColor: black,
        duration: 0,
        overwrite: true,
      });
      gsap.to(arrow, {
        backgroundColor: black,
        duration: 0,
        overwrite: true,
      });
      gsap.to(arrowInners, {
        visibility: "hidden",
        animationPlayState: "paused",
        duration: 0,
        overwrite: true,
      });
      gsap.to(triangle, {
        opacity: 0,
        duration: 0,
        overwrite: true,
      });
      gsap.to(lineHori, {
        transform: "scale(0, 1)",
        duration: duration,
        ease: "jet",
      });
      gsap.to(lineVert, {
        opacity: 0,
        duration: 0,
        overwrite: true,
      });
    };

    targets.forEach((target, index) => {
      target.addEventListener("mouseenter", () => {
        if (Common.isTouchDevice) return;
        fadeIn(
          target,
          redLinesFronts[index],
          redLinesBacks[index],
          arrows[index],
          arrowInners[index],
          targets.length,
          index
        );
      });
      target.addEventListener("mouseleave", () => {
        fadeOut(
          target,
          redLinesFronts[index],
          redLinesBacks[index],
          arrows[index],
          arrowInners[index],
          triangles[index],
          lineVerts[index]
        );
      });
      target.addEventListener("click", () => {
        const start = () => {
          gsap.to(target, {
            color: red,
            duration: pulseDuration,
            ease: "pulse",
          });
          gsap.to(arrows[index], {
            backgroundColor: red,
            duration: pulseDuration,
            ease: "pulse",
            overwrite: true,
          });
          gsap.to(redLinesFronts[index], {
            backgroundColor: red,
            duration: pulseDuration,
            ease: "pulse",
          });
          gsap.to(redLinesBacks[index], {
            backgroundColor: red,
            duration: pulseDuration,
            ease: "pulse",
          });
        };
        gsap.to(arrowInners[index], {
          display: "none",
          duration: 0,
          overwrite: true,
        });
        gsap.to(redLinesFronts[index], {
          backgroundColor: black,
          duration: 0,
          overwrite: true,
        });
        gsap.to(redLinesBacks[index], {
          backgroundColor: black,
          duration: 0,
          overwrite: true,
        });
        gsap.to(target, {
          color: black,
          duration: 0,
          overwrite: true,
          onComplete: start,
        });
      });
    });
  }
}

export default new FooterCta();
