import PageFooter from "../PageFooter";

class InterviewDetailsPage {
  constructor() {}
  init() {
    PageFooter.init();
  }
}

export default new InterviewDetailsPage();
