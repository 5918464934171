import gsap from "gsap";
import { red, white } from "../const/color";
import { pulseDuration } from "../utils/animation/pulseAnimation";
import Common from "./Common";

class Footer {
  constructor() {}
  init() {
    this.setHoverAnimation();
  }
  setHoverAnimation() {
    const logo = document.querySelector(".footer_logo");

    const textAnchors = document.querySelectorAll(".footer_anchorList_anchor");
    const footerIcons = document.querySelectorAll(".footer_icon");
    const footerIconWhites = document.querySelectorAll(
      ".footer_icon_img.--white"
    );
    const footerIconReds = document.querySelectorAll(".footer_icon_img.--red");

    const pageTopButtons = document.querySelectorAll(
      ".footer_pageTopButton_inner"
    );
    const pageTopButtonArrowWhites = document.querySelectorAll(
      ".footer_pageTopButton_img.--white"
    );
    const pageTopButtonArrowReds = document.querySelectorAll(
      ".footer_pageTopButton_img.--red"
    );

    const bunnerTop = document.querySelector(".footer_bunner.--top");
    const bunnerTopImg = document.querySelector(".footer_bunner_img.--top");
    const bunnerTopText = document.querySelector(".footer_bunner_text.--top");
    const bunnerTopArrowRed = document.querySelector(
      ".footer_bunner_arrow.--top.--red"
    );
    const bunnerTopArrowWhite = document.querySelector(
      ".footer_bunner_arrow.--top.--white"
    );

    const bunners = document.querySelectorAll(".footer_bunner.--bottom");
    const bunnerImgs = document.querySelectorAll(".footer_bunner_img.--bottom");

    const footerAnchors = document.querySelectorAll(
      ".footer_footerList_anchor"
    );

    const mouseEnterLogo = () => {
      gsap.to(logo, {
        opacity: 0.5,
        duration: 0,
      });
    };
    const mouseLeaveLogo = () => {
      gsap.to(logo, {
        opacity: 1,
        duration: 0,
      });
    };

    const mouseEnterTextAnchors = (target) => {
      gsap.to(target, {
        color: red,
        duration: 0,
      });
    };
    const mouseLeaveTextAnchors = (target) => {
      gsap.to(target, {
        color: white,
        duration: 0,
      });
    };

    const mouseEnterIcons = (white, red) => {
      gsap.to(white, {
        opacity: 0,
        duration: 0,
      });
      gsap.to(red, {
        opacity: 1,
        duration: 0,
      });
    };
    const mouseLeaveIcons = (white, red) => {
      gsap.to(white, {
        opacity: 1,
        duration: 0,
      });
      gsap.to(red, {
        opacity: 0,
        duration: 0,
      });
    };

    const mouseEnterPageTops = (target, whiteArrow, redArrow) => {
      gsap.to(target, {
        color: red,
        duration: 0,
      });
      gsap.to(redArrow, {
        opacity: 1,
        duration: 0,
      });
      gsap.to(whiteArrow, {
        opacity: 0,
        duration: 0,
      });
    };
    const mouseLeavePageTops = (target, whiteArrow, redArrow) => {
      gsap.to(target, {
        color: white,
        duration: 0,
      });
      gsap.to(redArrow, {
        opacity: 0,
        duration: 0,
      });
      gsap.to(whiteArrow, {
        opacity: 1,
        duration: 0,
      });
    };

    const mouseEnterBunnerTop = (
      bunnerTopImg,
      bunnerTopText,
      bunnerTopArrowRed,
      bunnerTopArrowWhite
    ) => {
      gsap.to(bunnerTopImg, {
        opacity: 0.9,
        duration: 0,
      });
      gsap.to(bunnerTopText, {
        color: red,
        duration: 0,
      });
      gsap.to(bunnerTopArrowRed, {
        opacity: 1,
        duration: 0,
      });
      gsap.to(bunnerTopArrowWhite, {
        opacity: 0,
        duration: 0,
      });
    };
    const mouseLeaveBunnerTop = (
      bunnerTopImg,
      bunnerTopText,
      bunnerTopArrowRed,
      bunnerTopArrowWhite
    ) => {
      gsap.to(bunnerTopImg, {
        opacity: 1,
        duration: 0,
      });
      gsap.to(bunnerTopText, {
        color: white,
        duration: 0,
      });
      gsap.to(bunnerTopArrowRed, {
        opacity: 0,
        duration: 0,
      });
      gsap.to(bunnerTopArrowWhite, {
        opacity: 1,
        duration: 0,
      });
    };

    const mouseEnterBunnerBottom = (img) => {
      gsap.to(img, {
        opacity: 0.9,
        duration: 0,
      });
    };
    const mouseLeaveBunnerBottom = (img) => {
      gsap.to(img, {
        opacity: 1,
        duration: 0,
      });
    };

    logo.addEventListener("mouseenter", () => {
      if (Common.isTouchDevice) return;
      mouseEnterLogo();
    });
    logo.addEventListener("mouseleave", () => {
      if (Common.isTouchDevice) return;
      mouseLeaveLogo();
    });
    textAnchors.forEach((target) => {
      target.addEventListener("mouseenter", () => {
        if (Common.isTouchDevice) return;
        mouseEnterTextAnchors(target);
      });
      target.addEventListener("mouseleave", () => {
        mouseLeaveTextAnchors(target);
      });
      target.addEventListener("click", () => {
        const start = () => {
          gsap.to(target, {
            color: white,
            duration: pulseDuration,
            ease: "pulse",
          });
        };
        gsap.to(target, {
          color: red,
          duration: 0,
          overwrite: true,
          onComplete: start,
        });
      });
    });
    footerIcons.forEach((target, index) => {
      const white = footerIconWhites[index];
      const red = footerIconReds[index];
      target.addEventListener("mouseenter", () => {
        if (Common.isTouchDevice) return;
        mouseEnterIcons(white, red);
      });
      target.addEventListener("mouseleave", () => {
        if (Common.isTouchDevice) return;
        mouseLeaveIcons(white, red);
      });
    });
    pageTopButtons.forEach((target, index) => {
      const white = pageTopButtonArrowWhites[index];
      const red = pageTopButtonArrowReds[index];
      target.addEventListener("mouseenter", () => {
        if (Common.isTouchDevice) return;
        mouseEnterPageTops(target, white, red);
      });
      target.addEventListener("mouseleave", () => {
        mouseLeavePageTops(target, white, red);
      });
    });

    bunnerTop.addEventListener("mouseenter", () => {
      if (Common.isTouchDevice) return;
      mouseEnterBunnerTop(
        bunnerTopImg,
        bunnerTopText,
        bunnerTopArrowRed,
        bunnerTopArrowWhite
      );
    });
    bunnerTop.addEventListener("mouseleave", () => {
      mouseLeaveBunnerTop(
        bunnerTopImg,
        bunnerTopText,
        bunnerTopArrowRed,
        bunnerTopArrowWhite
      );
    });
    bunnerTop.addEventListener("click", () => {
      const start = () => {
        gsap.to(bunnerTopText, {
          color: white,
          duration: pulseDuration,
          ease: "pulse",
        });
        gsap.to(bunnerTopArrowRed, {
          opacity: 0,
          duration: pulseDuration,
          ease: "pulse",
        });
        gsap.to(bunnerTopArrowWhite, {
          opacity: 1,
          duration: pulseDuration,
          ease: "pulse",
        });
        gsap.to(bunnerTopImg, {
          opacity: 1,
          duration: pulseDuration,
          ease: "pulse",
        });
      };
      gsap.to(bunnerTopText, {
        color: red,
        duration: 0,
        overwrite: true,
      });
      gsap.to(bunnerTopArrowRed, {
        opacity: 1,
        duration: 0,
        overwrite: true,
      });
      gsap.to(bunnerTopArrowWhite, {
        opacity: 0,
        duration: 0,
        overwrite: true,
      });
      gsap.to(bunnerTopImg, {
        opacity: 0.9,
        duration: 0,
        overwrite: true,
        onComplete: start,
      });
    });
    bunners.forEach((target, index) => {
      const img = bunnerImgs[index];
      target.addEventListener("mouseenter", () => {
        if (Common.isTouchDevice) return;
        mouseEnterBunnerBottom(img);
      });
      target.addEventListener("mouseleave", () => {
        mouseLeaveBunnerBottom(img);
      });
      target.addEventListener("click", () => {
        const start = () => {
          gsap.to(target, {
            opacity: 1,
            duration: pulseDuration,
            ease: "pulse",
          });
        };
        gsap.to(target, {
          opacity: 0.9,
          duration: 0,
          overwrite: true,
          onComplete: start,
        });
      });
    });
    footerAnchors.forEach((target) => {
      target.addEventListener("mouseenter", () => {
        if (Common.isTouchDevice) return;
        mouseEnterTextAnchors(target);
      });
      target.addEventListener("mouseleave", () => {
        if (Common.isTouchDevice) return;
        mouseLeaveTextAnchors(target);
      });
    });
  }
}

export default new Footer();
