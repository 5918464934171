import gsap from "gsap";
import {
  defaultDuration,
  longerDuration,
  longestDuration,
  shortDuration,
  shorterDuration,
} from "../../const/animation";
import { wait } from "../wait";
import { fadeInPulseAnimation } from "./pulseAnimation";
import { fadeInBorder } from "./borderAnimation";
import {
  fadeInTileAnimation,
  fadeInTileAnimations,
  fadeInTriangleAnimation,
  fadeOutTileAnimation,
  tileInt,
  tileDuration,
} from "./tileAnimation";
import { slideInImg, slideInImgs } from "./imgAnimation";

export const inViewImgBox = () => {
  const monitors = document.querySelectorAll(".js--inViewImgContainer");
  const screens = document.querySelectorAll(".js--inViewImgScreen");
  const imgs = document.querySelectorAll(".js--inViewImg");

  const options = {
    root: null,
    rootMargin: "-20% 0px",
    threshold: 0,
  };

  monitors.forEach((monitor, index) => {
    const callBack = (entry) => {
      if (entry[0].isIntersecting) {
        const screen = screens[index];
        const img = imgs[index];
        fadeInTileAnimation(screen);
        const duration = shortDuration;
        wait(0)
          .then(() => {
            fadeInTileAnimation(screen);
            return wait(duration);
          })
          .then(() => {
            img.style.visibility = "visible";
            fadeOutTileAnimation(screen);
          });
        observer.unobserve(entry[0].target);
      }
    };
    const observer = new IntersectionObserver(callBack, options);
    observer.observe(monitor);
  });
};

export const inViewTriangles = () => {
  const targets = document.querySelectorAll(".triangle.js--inview");

  const options = {
    root: null,
    rootMargin: "-20% 0px",
    threshold: 0,
  };
  targets.forEach((target) => {
    const callBack = (entry) => {
      if (entry[0].isIntersecting) {
        fadeInPulseAnimation(target);
        observer.unobserve(entry[0].target);
      }
    };
    const observer = new IntersectionObserver(callBack, options);
    observer.observe(target);
  });
};

export const inViewRedLines = () => {
  const animationTargets = document.querySelectorAll(
    ".l-content .redLine.js--inview"
  );
  const options = {
    root: null,
    rootMargin: "-20% 0px",
    threshold: 0,
  };
  const callBack = (entry) => {
    if (entry[0].isIntersecting) {
      const target = entry[0].target.firstElementChild;
      gsap.to(target, {
        transform: "translate3d(0, 0, 0)",
        duration: defaultDuration,
        ease: "jet",
        delay: shortDuration,
      });
      observer.unobserve(entry[0].target);
    }
  };
  const observer = new IntersectionObserver(callBack, options);
  animationTargets.forEach((target) => {
    observer.observe(target);
  });
};

export const inViewBlackLines = () => {
  const animationTargets = document.querySelectorAll(
    ".l-content .blackLine.js--inview"
  );

  const options = {
    root: null,
    rootMargin: "-20% 0px",
    threshold: 0,
  };
  const callBack = (entry) => {
    if (entry[0].isIntersecting) {
      const target = entry[0].target.firstElementChild;
      gsap.to(target, {
        transform: "translate3d(0, 0, 0)",
        duration: defaultDuration,
        ease: "jet",
        delay: shorterDuration,
      });
      observer.unobserve(entry[0].target);
    }
  };
  const observer = new IntersectionObserver(callBack, options);
  animationTargets.forEach((target) => {
    observer.observe(target);
  });
};

export const inViewFooter = () => {
  const monitored = document.querySelector(".footer_anchorLists");
  const animationTargets = document.querySelectorAll(
    ".footer_anchorLists .redLine_container"
  );
  const tileElms = document.querySelectorAll(
    ".footer_container .js--tileAnimationElm"
  );
  const options = {
    root: null,
    rootMargin: "-20% 0px",
    threshold: 0,
  };
  const callBack = (entry) => {
    if (entry[0].isIntersecting) {
      fadeInTileAnimations(tileElms);
      animationTargets.forEach((target) => {
        gsap.to(target, {
          transform: "translate3d(0, 0, 0)",
          duration: defaultDuration,
          ease: "jet",
          delay: shortDuration,
        });
      });
      observer.unobserve(entry[0].target);
    }
  };
  const observer = new IntersectionObserver(callBack, options);
  observer.observe(monitored);
};

export const inVIewnoBgButton = () => {
  const buttons = document.querySelectorAll(".noBgButton");
  const jpTextsPc = document.querySelectorAll(".noBgButton_text.--pc.--jp");
  const jpTextsSp = document.querySelectorAll(".noBgButton_text.--sp.--jp");
  const enTexts = document.querySelectorAll(".noBgButton_text.--en");
  const triangles = document.querySelectorAll(".noBgButton_triangle");
  const arrows = document.querySelectorAll(".noBgButton_arrow");

  const options = {
    root: null,
    rootMargin: "-20% 0px",
    threshold: 0,
  };

  buttons.forEach((button, index) => {
    const jpTextPc = jpTextsPc[index];
    const jpTextSp = jpTextsSp[index];
    const enText = enTexts[index];
    const triangle = triangles[index];
    const arrow = arrows[index];

    const callBack = (entry) => {
      if (entry[0].isIntersecting) {
        const duration = defaultDuration * 0.03;
        wait(duration)
          .then(() => {
            fadeInTileAnimation(triangle, shorterDuration, "pulse");
            return wait(duration);
          })
          .then(() => {
            fadeInTileAnimation(enText, shorterDuration, "pulse");
            return wait(duration);
          })
          .then(() => {
            fadeInTileAnimation(jpTextPc, shorterDuration, "pulse");
            fadeInTileAnimation(jpTextSp, shorterDuration, "pulse");
            return wait(duration);
          })
          .then(() => {
            fadeInTileAnimation(arrow, shorterDuration, "pulse");
          });
        observer.unobserve(entry[0].target);
      }
    };

    const observer = new IntersectionObserver(callBack, options);
    observer.observe(button);
  });
};

export const inViewTopFlow = () => {
  const wrapper = document.querySelector(".flow");
  const imgScreens = document.querySelectorAll(".flow .js--slideInImg_screen");
  const imgs = document.querySelectorAll(".flow .js--slideInImg_img");
  const elms = document.querySelectorAll(".flow .js--tileAnimationElm");
  const options = {
    root: null,
    rootMargin: "-20% 0px",
    threshold: 0,
  };
  const start = (entry) => {
    if (entry[0].isIntersecting) {
      slideInImgs(imgs, imgScreens);
      fadeInTileAnimations(elms);
      observer.unobserve(entry[0].target);
    }
  };
  const observer = new IntersectionObserver(start, options);
  observer.observe(wrapper);
};

export const inViewTopAbout = () => {
  const about = document.querySelector(".about");
  const texts = document.querySelectorAll(".about_imgs_text");
  const imgContainer = document.querySelector(".about_imgs");
  const screenTop = document.querySelector(".about_imgScreen.--top");
  const screenBottom = document.querySelector(".about_imgScreen.--bottom");
  const screenLeft = document.querySelector(".about_imgScreen.--left");
  const screenRight = document.querySelector(".about_imgScreen.--right");
  const options = {
    root: null,
    rootMargin: "-50% 0px",
    threshold: 0,
  };
  const start = (entry) => {
    if (entry[0].isIntersecting) {
      gsap.to(screenTop, {
        transform: "scale(1, 0)",
        duration: shortDuration,
        ease: "jet",
      });
      gsap.to(screenBottom, {
        transform: "scale(1, 0)",
        duration: shortDuration,
        ease: "jet",
      });
      gsap.to(screenLeft, {
        transform: "scale(0, 1)",
        duration: shortDuration,
        ease: "jet",
      });
      gsap.to(screenRight, {
        transform: "scale(0, 1)",
        duration: shortDuration,
        ease: "jet",
      });
      gsap.to(imgContainer, {
        transform: "scale(1)",
        duration: longestDuration,
        ease: "jet",
      });
      setTimeout(() => {
        fadeInTileAnimations(texts, tileInt, tileDuration * 2);
      }, shortDuration * 1000);
      observer.unobserve(entry[0].target);
    }
  };
  const observer = new IntersectionObserver(start, options);
  observer.observe(about);
};

export const inViewTopPointPc = () => {
  const parentNode = ".points_body.--pc";
  const points = document.querySelector(".points");
  const imgs = document.querySelectorAll(`${parentNode} .js--slideInImg_img`);
  const imgScreens = document.querySelectorAll(
    `${parentNode} .js--slideInImg_screen`
  );
  const bunners = document.querySelectorAll(
    `${parentNode} .points_bunnerList_item`
  );
  const texts = document.querySelectorAll(
    `${parentNode} .points_textList_texts`
  );
  const anchors = document.querySelectorAll(
    `${parentNode} .strokeButton_texts`
  );
  const lines = document.querySelectorAll(`${parentNode} .redLine_container`);
  const buttonLineTops = document.querySelectorAll(
    `${parentNode} .strokeButton_line.--top`
  );
  const buttonLineLefts = document.querySelectorAll(
    `${parentNode} .strokeButton_line.--left`
  );
  const buttonLineBottoms = document.querySelectorAll(
    `${parentNode} .strokeButton_line.--bottom`
  );
  const buttonLineRights = document.querySelectorAll(
    `${parentNode} .strokeButton_line.--right`
  );

  const options = {
    root: null,
    rootMargin: "-20% 0px",
    threshold: 0,
  };

  const fadeInLine = (target) => {
    gsap.to(target, {
      transform: "scale(1, 1)",
      duration: defaultDuration,
      ease: "jet",
    });
  };

  const act1 = () => {
    fadeInTileAnimations(bunners);
    slideInImgs(imgs, imgScreens);
  };
  const act2 = () => {
    fadeInTileAnimations(texts);
    lines.forEach((line) => {
      gsap.to(line, {
        transform: "translate3d(0, 0, 0)",
        duration: defaultDuration,
        ease: "jet",
        delay: shortDuration,
      });
    });
  };
  const act3 = () => {
    anchors.forEach((anchor, index) => {
      anchor.style.pointerEvents = "auto";
      const top = buttonLineTops[index];
      const bottom = buttonLineBottoms[index];
      const left = buttonLineLefts[index];
      const right = buttonLineRights[index];
      fadeInTileAnimation(anchor);
      fadeInLine(top);
      fadeInLine(bottom);
      fadeInLine(left);
      fadeInLine(right);
    });
  };

  const start = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const duration = defaultDuration;
        wait(duration)
          .then(() => {
            act1();
            return wait(duration);
          })
          .then(() => {
            act2();
            return wait(duration);
          })
          .then(() => {
            act3();
          });
        observer.unobserve(entry.target);
      }
    });
  };
  const observer = new IntersectionObserver(start, options);
  observer.observe(points);
};

export const inViewTopPointSp = () => {
  const parentNode = ".points_body.--sp";
  const points = document.querySelectorAll(
    `${parentNode} .points_imgList_item`
  );
  const imgs = document.querySelectorAll(`${parentNode} .js--slideInImg_img`);
  const imgScreens = document.querySelectorAll(
    `${parentNode} .js--slideInImg_screen`
  );
  const bunners = document.querySelectorAll(
    `${parentNode} .points_bunnerList_item`
  );
  const texts = document.querySelectorAll(
    `${parentNode} .points_textList_texts`
  );
  const anchors = document.querySelectorAll(
    `${parentNode} .strokeButton_texts`
  );
  const lines = document.querySelectorAll(`${parentNode} .redLine_container`);
  const buttonLineTops = document.querySelectorAll(
    `${parentNode} .strokeButton_line.--top`
  );
  const buttonLineLefts = document.querySelectorAll(
    `${parentNode} .strokeButton_line.--left`
  );
  const buttonLineBottoms = document.querySelectorAll(
    `${parentNode} .strokeButton_line.--bottom`
  );
  const buttonLineRights = document.querySelectorAll(
    `${parentNode} .strokeButton_line.--right`
  );

  const options = {
    root: null,
    rootMargin: "-20% 0px",
    threshold: 0,
  };

  const fadeInLine = (target) => {
    gsap.to(target, {
      transform: "scale(1, 1)",
      duration: defaultDuration,
      ease: "jet",
    });
  };

  points.forEach((point, index) => {
    const act1 = () => {
      fadeInTileAnimation(bunners[index]);
      slideInImg(imgs[index], imgScreens[index]);
    };

    const act2 = () => {
      fadeInTileAnimation(texts[index]);
      gsap.to(lines[index], {
        transform: "translate3d(0, 0, 0)",
        duration: defaultDuration,
        ease: "jet",
        delay: shortDuration,
      });
    };

    const act3 = () => {
      anchors[index].style.pointerEvents = "auto";
      const top = buttonLineTops[index];
      const bottom = buttonLineBottoms[index];
      const left = buttonLineLefts[index];
      const right = buttonLineRights[index];
      fadeInTileAnimation(anchors[index]);
      fadeInLine(top);
      fadeInLine(bottom);
      fadeInLine(left);
      fadeInLine(right);
    };
    const start = (entries) => {
      if (entries[0].isIntersecting) {
        const duration = defaultDuration;
        wait(duration)
          .then(() => {
            act1();
            return wait(duration);
          })
          .then(() => {
            act2();
            return wait(duration);
          })
          .then(() => {
            act3();
          });
        observer.unobserve(entries[0].target);
      }
    };
    const observer = new IntersectionObserver(start, options);
    observer.observe(point);
  });
};

export const inViewFooterCta = () => {
  const footerCta = document.querySelector(".footerCta");
  const counterInviewElm1 = document.querySelectorAll(
    ".footerCta_counter_scales.--first .js--tileAnimationElm"
  );
  const counterInviewElm2 = document.querySelectorAll(
    ".footerCta_counter_scales.--second .js--tileAnimationElm"
  );
  const counterInviewElm3 = document.querySelectorAll(
    ".footerCta_counter_scales.--third .js--tileAnimationElm"
  );
  const counterInviewElm4 = document.querySelectorAll(
    ".footerCta_counter_scales.--tail .js--tileAnimationElm"
  );
  const cardInviewElm = document.querySelectorAll(
    ".footerCta_list .js--tileAnimationElm"
  );
  const horiLine = document.querySelector(".footerCta_counterLineBox");
  const lines = document.querySelectorAll(".footerCta_redLine_container");
  const triangle = document.querySelector(".footerCta_counterTriangle");
  const currentLine = document.querySelector(".footerCta_currentLine");

  const options = {
    root: null,
    rootMargin: "-20% 0px",
    threshold: 0,
  };
  const callBack = (entry) => {
    if (entry[0].isIntersecting) {
      const duration = shortDuration;
      wait(duration)
        .then(() => {
          fadeInTileAnimations(cardInviewElm, 0);
          return wait(duration);
        })
        .then(() => {
          fadeInTileAnimation(currentLine);
          fadeInTileAnimations(counterInviewElm1, tileInt * 0.5);
          fadeInTileAnimations(counterInviewElm2, tileInt * 0.5);
          fadeInTileAnimations(counterInviewElm3, tileInt * 0.5);
          fadeInTileAnimations(counterInviewElm4, tileInt * 0.5);
          fadeInTileAnimation(horiLine);
          return wait(duration);
        })
        .then(() => {
          lines.forEach((line) => {
            gsap.to(line, {
              transform: "translate3d(0, 0, 0)",
              duration: defaultDuration,
              ease: "jet",
            });
          });
          fadeInTriangleAnimation(triangle);
          footerCta.style.pointerEvents = "auto";
        });
      observer.unobserve(entry[0].target);
    }
  };
  const observer = new IntersectionObserver(callBack, options);
  observer.observe(footerCta);
};

export const inViewFadeIn = (items) => {
  const options = {
    root: null,
    rootMargin: "0px 0px",
    threshold: 0.25,
  };
  const start = (entries) => {
    const int = defaultDuration * 0.025;
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        items.forEach((item, index) => {
          setTimeout(() => {
            gsap.to(item, {
              opacity: 1,
              duration: longerDuration,
              ease: "jet",
            });
          }, int * index * 1000);
        });
        observer.unobserve(entry.target);
      }
    });
  };
  const observer = new IntersectionObserver(start, options);
  observer.observe(items[0]);
};

export const inviewStorkeButton = () => {
  const anchors = document.querySelectorAll(".strokeButton.js--inview");
  const tests = document.querySelectorAll(
    ".strokeButton.js--inview .strokeButton_texts"
  );
  const lineTops = document.querySelectorAll(
    ".strokeButton.js--inview .strokeButton_line.--top"
  );
  const lineBottoms = document.querySelectorAll(
    ".strokeButton.js--inview .strokeButton_line.--bottom"
  );
  const lineLefts = document.querySelectorAll(
    ".strokeButton.js--inview .strokeButton_line.--left"
  );
  const lineRights = document.querySelectorAll(
    ".strokeButton.js--inview .strokeButton_line.--right"
  );

  const fadeInLine = (target) => {
    gsap.to(target, {
      transform: "scale(1, 1)",
      duration: defaultDuration,
      ease: "jet",
    });
  };

  anchors.forEach((anchor, index) => {
    anchor.style.pointerEvents = "auto";
    const top = lineTops[index];
    const bottom = lineBottoms[index];
    const left = lineLefts[index];
    const right = lineRights[index];
    fadeInTileAnimation(anchor);
    fadeInTileAnimation(tests);
    fadeInLine(top);
    fadeInLine(bottom);
    fadeInLine(left);
    fadeInLine(right);
  });
};

export const inviewSctionHeader = () => {
  const anchors = document.querySelectorAll(".sectionHeader.--border");
  const titlesPc = document.querySelectorAll(
    ".sectionHeader.--border .sectionHeader_titles.--pc"
  );
  const titlesSp = document.querySelectorAll(
    ".sectionHeader.--border .sectionHeader_titles.--sp"
  );
  const subs = document.querySelectorAll(
    ".sectionHeader.--border .sectionHeader_sub"
  );
  const borders = document.querySelectorAll(
    ".sectionHeader.--border .blackLine_container"
  );

  const options = {
    root: null,
    rootMargin: "-20% 0px",
    threshold: 0,
  };
  anchors.forEach((anchor, index) => {
    const titlePc = titlesPc[index];
    const textsPc = [...Array(titlePc.childElementCount)].map(
      (_, lineIndex) => {
        const line = titlePc.children[lineIndex];
        return [...Array(line.childElementCount)].map((_, textIndex) => {
          const text = line.children[textIndex];
          return text;
        });
      }
    );
    const titleSp = titlesSp[index];
    const textsSp = [...Array(titleSp.childElementCount)].map(
      (_, lineIndex) => {
        const line = titleSp.children[lineIndex];
        return [...Array(line.childElementCount)].map((_, textIndex) => {
          const text = line.children[textIndex];
          return text;
        });
      }
    );
    const sub = subs[index];
    const border = borders[index];
    const callBack = (entry) => {
      if (entry[0].isIntersecting) {
        textsPc.forEach((texts, index) => {
          setTimeout(() => {
            fadeInTileAnimations(texts);
          }, tileInt * index * 2000);
        });
        textsSp.forEach((texts, index) => {
          setTimeout(() => {
            fadeInTileAnimations(texts);
          }, tileInt * index * 2000);
        });
        fadeInTileAnimation(sub);
        gsap.to(border, {
          transform: "translate3d(0, 0, 0)",
          duration: defaultDuration,
          ease: "jet",
          delay: shortDuration,
        });
        observer.unobserve(entry[0].target);
      }
    };

    const observer = new IntersectionObserver(callBack, options);
    observer.observe(anchor);
  });
};

export const inviewSctionHeaderNoBorder = () => {
  const anchors = document.querySelectorAll(".sectionHeader.--noBorder");
  const titlesPc = document.querySelectorAll(
    ".sectionHeader.--noBorder .sectionHeader_titles.--pc"
  );
  const titlesSp = document.querySelectorAll(
    ".sectionHeader.--noBorder .sectionHeader_titles.--sp"
  );
  const subs = document.querySelectorAll(
    ".sectionHeader.--noBorder .sectionHeader_sub"
  );
  const options = {
    root: null,
    rootMargin: "-20% 0px",
    threshold: 0,
  };
  anchors.forEach((anchor, index) => {
    const titlePc = titlesPc[index];
    const textsPc = [...Array(titlePc.childElementCount)].map(
      (_, lineIndex) => {
        const line = titlePc.children[lineIndex];
        return [...Array(line.childElementCount)].map((_, textIndex) => {
          const text = line.children[textIndex];
          return text;
        });
      }
    );
    const titleSp = titlesSp[index];
    const textsSp = [...Array(titleSp.childElementCount)].map(
      (_, lineIndex) => {
        const line = titleSp.children[lineIndex];
        return [...Array(line.childElementCount)].map((_, textIndex) => {
          const text = line.children[textIndex];
          return text;
        });
      }
    );
    const sub = subs[index];
    const callBack = (entry) => {
      if (entry[0].isIntersecting) {
        textsPc.forEach((texts, index) => {
          setTimeout(() => {
            fadeInTileAnimations(texts);
          }, tileInt * index * 2000);
        });
        textsSp.forEach((texts, index) => {
          setTimeout(() => {
            fadeInTileAnimations(texts);
          }, tileInt * index * 2000);
        });
        fadeInTileAnimation(sub);
        observer.unobserve(entry[0].target);
      }
    };

    const observer = new IntersectionObserver(callBack, options);
    observer.observe(anchor);
  });
};

export const inviewWideList = (parentNode) => {
  const anchor = document.querySelector(`${parentNode} .wideList`);
  if (!anchor) console.error("要素がうまく指定できていません");
  const borders = document.querySelectorAll(
    `${parentNode} .wideList .blackLine_container`
  );
  const titles = document.querySelectorAll(
    `${parentNode} .wideList .wideList_titles`
  );
  const subs = document.querySelectorAll(
    `${parentNode} .wideList .wideList_subs`
  );
  const arrows = document.querySelectorAll(
    `${parentNode} .wideList .wideList_imgBox`
  );
  const anchors = document.querySelectorAll(
    `${parentNode} .wideList .wideList_anchor`
  );

  const options = {
    root: null,
    rootMargin: "-20% 0px",
    threshold: 0,
  };

  const callBack = (entry) => {
    if (entry[0].isIntersecting) {
      titles.forEach((title, index) => {
        setTimeout(() => {
          gsap.to(arrows[index], {
            opacity: 1,
            transform: "translate3d(0, 0, 0)",
            duration: longerDuration,
            ease: "jet",
          });
          gsap.to(title, {
            opacity: 1,
            duration: tileDuration,
            ease: "pulse",
            delay: shortDuration,
          });
          gsap.to(subs[index], {
            opacity: 1,
            duration: tileDuration,
            ease: "pulse",
            delay: shortDuration,
          });
          anchors[index].style.pointerEvents = "auto";
        }, tileInt * index * 1000);
      });
      borders.forEach((border, index) => {
        setTimeout(() => {
          fadeInBorder(border, 0);
        }, tileInt * index * 1000);
      });
      observer.unobserve(entry[0].target);
    }
  };

  const observer = new IntersectionObserver(callBack, options);
  observer.observe(anchor);
};
