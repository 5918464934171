import gsap from "gsap";
import { shortDuration } from "../../const/animation";
import { black, red } from "../../const/color";
import { pulseDuration } from "../../utils/animation/pulseAnimation";
import Common from "../Common";
import PageNation from "../PageNation";
import Search from "../Search";

export class NewsPage {
  constructor() {}
  init() {
    PageNation.init();
    Search.init();
    this.setMouseAnimation();
  }
  setMouseAnimation() {
    const targets = document.querySelectorAll(".newsList_anchor");
    const covers = document.querySelectorAll(".newsList_imgScreen");
    const titles = document.querySelectorAll(".newsList_title");

    targets.forEach((target, index) => {
      const title = titles[index];
      const cover = covers[index];
      target.addEventListener("mouseenter", () => {
        if (Common.isTouchDevice) return;
        title.style.color = red;
        const start = () => {
          gsap.to(cover, {
            opacity: 0,
            duration: pulseDuration,
            ease: "pulse",
          });
        };
        gsap.to(cover, {
          opacity: 1,
          duration: 0,
          overwrite: true,
          onComplete: start,
        });
      });
      target.addEventListener("mouseleave", () => {
        title.style.color = black;
      });
      target.addEventListener("click", () => {
        const start = () => {
          gsap.to(title, {
            color: red,
            duration: pulseDuration,
            ease: "pulse",
          });
        };
        gsap.to(title, {
          color: black,
          duration: 0,
          onComplete: start,
        });
      });
    });

    const setTags = () => {
      const targets = document.querySelectorAll(".newsList_tag");
      targets.forEach((target) => {
        target.addEventListener("mouseenter", () => {
          if (Common.isTouchDevice) return;
          gsap.to(target, {
            opacity: 0.75,
            duration: shortDuration,
            ease: "jet",
          });
        });
        target.addEventListener("mouseleave", () => {
          gsap.to(target, {
            opacity: 1,
            duration: shortDuration,
            ease: "jet",
          });
        });
        target.addEventListener("click", () => {
          const start = () => {
            gsap.to(target, {
              opacity: 0.75,
              duration: pulseDuration,
              ease: "pulse",
            });
          };
          gsap.to(target, {
            opacity: 1,
            duration: 0,
            overwrite: true,
            onComplete: start,
          });
        });
      });
    };

    setTags();
  }
}

export default new NewsPage();
