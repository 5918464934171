import gsap from "gsap";
import { black, red, white } from "../../const/color";
import { pulseDuration } from "../../utils/animation/pulseAnimation";
import Common from "../Common";

class ContactFormPage {
  constructor() {}
  init() {
    this.mouseAnimation();
  }
  mouseAnimation() {
    const setSubmitButton = () => {
      const button = document.querySelector(".submit-btn input");
      const bg = document.querySelector(".submit-btn_bg");
      const bgInner = document.querySelector(".submit-btn_bg_inner");
      const text = document.querySelector(".submit-btn_text");
      const arrowBlack = document.querySelector(".submit-btn_arrow.--black");
      const arrowWhite = document.querySelector(".submit-btn_arrow.--white");

      if (!button) return;

      button.addEventListener("mouseenter", () => {
        if (Common.isTouchDevice) return;
        bgInner.style.animationPlayState = "running";
        bg.style.visibility = "visible";
        gsap.to(text, {
          color: white,
          duration: pulseDuration,
          ease: "pulse",
        });
        gsap.to(arrowWhite, {
          opacity: 1,
          duration: pulseDuration,
          ease: "pulse",
        });
        gsap.to(arrowBlack, {
          opacity: 0,
          duration: pulseDuration,
          ease: "pulse",
        });
      });
      button.addEventListener("mouseleave", () => {
        bgInner.style.animationPlayState = "paused";
        bg.style.visibility = "hidden";
        gsap.to(arrowWhite, {
          opacity: 0,
          duration: 0,
          overwrite: true,
        });
        gsap.to(arrowBlack, {
          opacity: 1,
          duration: 0,
          overwrite: true,
        });
        gsap.to(text, {
          color: black,
          duration: 0,
          overwrite: true,
        });
      });
      button.addEventListener("click", () => {
        const start = () => {
          gsap.to(text, {
            color: white,
            duration: pulseDuration,
            ease: "pulse",
          });
          gsap.to(arrowWhite, {
            opacity: 1,
            duration: pulseDuration,
            ease: "pulse",
          });
        };
        bgInner.style.animationPlayState = "running";
        bg.style.visibility = "visible";
        gsap.to(arrowWhite, {
          opacity: 0,
          duration: 0,
          overwrite: true,
        });
        gsap.to(arrowBlack, {
          opacity: 0,
          duration: 0,
          overwrite: true,
        });
        gsap.to(text, {
          color: black,
          duration: 0,
          overwrite: true,
          onComplete: start,
        });
      });
    };
    const setBunner = (node) => {
      const target = document.querySelector(node);

      if (!target) return;

      target.addEventListener("mouseenter", () => {
        if (Common.isTouchDevice) return;
        target.style.opacity = ".75";
      });
      target.addEventListener("mouseleave", () => {
        target.style.opacity = "1";
      });
      target.addEventListener("click", () => {
        const start = () => {
          gsap.to(target, {
            opacity: 1,
            duration: pulseDuration,
            ease: "pulse",
          });
        };
        gsap.to(target, {
          opacity: 0.75,
          duration: 0,
          overwrite: true,
          onComplete: start,
        });
      });
    };
    const setPrivacyPolicy = () => {
      const target = document.querySelector(".label-link");
      if (!target) return;
      target.addEventListener("mouseenter", () => {
        if (Common.isTouchDevice) return;
        target.style.color = red;
        target.style.borderBottomColor = red;
      });
      target.addEventListener("mouseleave", () => {
        target.style.color = black;
        target.style.borderBottomColor = black;
      });
      target.addEventListener("click", () => {
        const start = () => {
          gsap.to(target, {
            color: red,
            borderBottomColor: red,
            duration: pulseDuration,
            ease: "pulse",
          });
        };
        gsap.to(target, {
          color: black,
          borderBottomColor: black,
          duration: 0,
          overwrite: true,
          onComplete: start,
        });
      });
    };

    setSubmitButton();
    setBunner(".sideMenu_bunner");
    setBunner(".back-button");
    setPrivacyPolicy();
  }
}

export default new ContactFormPage();
