import gsap from "gsap";
import { shortDuration } from "../../const/animation.js";
import { black, red, white } from "../../const/color.js";
import { inViewTileLines } from "../../utils/animation/tileAnimation.js";
import Common from "../Common.js";
import FaqList from "../FaqList";
import PageFooter from "../PageFooter.js";
import { pulseDuration } from "../../utils/animation/pulseAnimation.js";

class TrainingPage {
  constructor() {}
  init() {
    this.setSideMenuEvent();
    this.setInviewAnimation();
    FaqList.init();
    PageFooter.init();
  }
  setSideMenuEvent() {
    const container = document.querySelector(".trainingPage");

    const markCurrent = (anchorNode, targetNode) => {
      const targets = document.querySelectorAll(
        `.sideMenu_anchor${targetNode}`
      );
      if (container.classList.contains(anchorNode)) {
        targets.forEach((target) => {
          target.classList.add("js--current");
        });
      }
    };
    markCurrent("bodyMakePage", ".js--toBodyMake");
    markCurrent("hipUpPage", ".js--toHipUp");
    markCurrent("maternityPage", ".js--toMaternity");
    markCurrent("dietPage", ".js--toDiet");
    markCurrent("chiropracticPage", ".js--toChiropractic");
    markCurrent("pilatesPage", ".js--toPilates");
    markCurrent("semiPage", ".js--toSemi");
    markCurrent("onsitePage", ".js--toOnSite");
    markCurrent("onlinePage", ".js--toOnline");

    const anchorsPc = document.querySelectorAll(
      ".sideMenu.--pc .sideMenu_anchor"
    );
    const anchorsSp = document.querySelectorAll(
      ".sideMenu.--sp .sideMenu_anchor"
    );
    anchorsPc.forEach((anchor) => {
      anchor.addEventListener("mouseenter", () => {
        if (Common.isTouchDevice) return;
        gsap.to(anchor, {
          color: red,
          borderColor: red,
          duration: 0,
        });
      });
      anchor.addEventListener("mouseleave", () => {
        gsap.to(anchor, {
          color: black,
          borderColor: black,
          duration: 0,
        });
      });
      anchor.addEventListener("click", () => {
        const start = () => {
          gsap.to(anchor, {
            color: black,
            borderColor: black,
            duration: pulseDuration,
            ease: "pulse",
          });
        };
        gsap.to(anchor, {
          color: red,
          borderColor: red,
          duration: 0,
          onComplete: start,
        });
      });
    });
    anchorsSp.forEach((anchor) => {
      anchor.addEventListener("mouseenter", () => {
        if (Common.isTouchDevice) return;
        gsap.to(anchor, {
          color: red,
          borderColor: red,
          duration: 0,
        });
      });
      anchor.addEventListener("mouseleave", () => {
        gsap.to(anchor, {
          color: white,
          borderColor: white,
          duration: 0,
        });
      });
      anchor.addEventListener("click", () => {
        const start = () => {
          gsap.to(anchor, {
            color: white,
            borderColor: white,
            duration: pulseDuration,
            ease: "pulse",
          });
        };
        gsap.to(anchor, {
          color: red,
          borderColor: red,
          duration: 0,
          onComplete: start,
        });
      });
    });
  }
  setInviewAnimation() {
    inViewTileLines(".point_body");
    inViewTileLines(".genderDifferences_container");
  }
  resize() {}
}
export default new TrainingPage();
