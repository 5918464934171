import { fadeInBorder } from "./borderAnimation";
import { fadeInTileAnimation, fadeInTileAnimations } from "./tileAnimation";

export const enterPageHeader = () => {
  const pageHeader = document.querySelector(".pageHeader");
  if (!pageHeader) return;
  const titleTexts = document.querySelectorAll(".pageHeader_title_text");
  const sub = document.querySelector(".pageHeader_sub");
  const border = document.querySelector(".pageHeader .blackLine_container");
  const textBox = document.querySelector(".pageHeader_itemBox.--right");

  fadeInTileAnimations(titleTexts);
  fadeInTileAnimation(textBox);
  fadeInTileAnimation(sub);
  fadeInBorder(border, 0);
};
