import { gsap } from "gsap";
import Common from "./Common";
import { pulseDuration } from "../utils/animation/pulseAnimation";
import { getWinSize } from "../utils/getWinSIze";
import { spView } from "../const/breakPoint";
import { shorterDuration } from "../const/animation";
import { greyGradation } from "../const/color";
import { white } from "../const/color";
import { red } from "../const/color";
import { black } from "../const/color";

class FaqList {
  constructor() {}
  init() {
    const buttons = document.querySelectorAll(".faq_card_head");
    const buttonTextBoxes = document.querySelectorAll(".faq_card_head_textBox");
    const numbers = document.querySelectorAll(".faq_card_number");
    const questionsPc = document.querySelectorAll(".faq_card_question.--pc");
    const questionsSp = document.querySelectorAll(".faq_card_question.--sp");
    const answers = document.querySelectorAll(".faq_card_body");
    const answerTextsPc = document.querySelectorAll(".faq_card_answer.--pc");
    const answerTextsSp = document.querySelectorAll(".faq_card_answer.--sp");
    const lines = document.querySelectorAll(".faq_card_head_line.--vert");

    buttons.forEach((button, index) => {
      let isOpen = false;
      const buttonTextBox = buttonTextBoxes[index];
      const number = numbers[index];
      const questionPc = questionsPc[index];
      const questionSp = questionsSp[index];
      const answer = answers[index];
      const answerTextPc = answerTextsPc[index];
      const answerTextSp = answerTextsSp[index];
      const line = lines[index];

      button.addEventListener("mouseenter", () => {
        const winSize = getWinSize();
        const isSp = winSize <= spView;
        if (Common.isTouchDevice) return;
        button.style.background = greyGradation;
        number.style.color = white;
        questionPc.style.color = white;
        questionSp.style.color = white;
        if (!isSp) {
          gsap.to(buttonTextBox, {
            transform: "translate3d(30rem, 0, 0)",
            duration: shorterDuration,
            ease: "jet",
          });
        } else {
          gsap.to(buttonTextBox, {
            transform: "translate3d(16rem, 0, 0)",
            duration: shorterDuration,
            ease: "jet",
          });
        }
      });
      button.addEventListener("mouseleave", () => {
        if (!isOpen) {
          button.style.background = white;
          number.style.color = red;
          questionPc.style.color = black;
          questionSp.style.color = black;
          gsap.to(buttonTextBox, {
            transform: "translate3d(0rem, 0, 0)",
            duration: shorterDuration,
            ease: "jet",
          });
        }
      });
      button.addEventListener("click", () => {
        if (!isOpen) {
          answer.classList.remove("js--none");
          gsap.to(answerTextPc, {
            opacity: 1,
            duration: pulseDuration,
            ease: "pulse",
          });
          gsap.to(answerTextSp, {
            opacity: 1,
            duration: pulseDuration,
            ease: "pulse",
          });
          line.style.opacity = 0;

          if (Common.isTouchDevice) {
            button.style.background = greyGradation;
            number.style.color = white;
            questionPc.style.color = white;
            questionSp.style.color = white;
            const winSize = getWinSize();
            const isSp = winSize <= spView;
            if (!isSp) {
              gsap.to(buttonTextBox, {
                transform: "translate3d(30rem, 0, 0)",
                duration: 0,
              });
            } else {
              gsap.to(buttonTextBox, {
                transform: "translate3d(16rem, 0, 0)",
                duration: 0,
              });
            }
          }

          isOpen = true;
        } else {
          answer.classList.add("js--none");
          gsap.to(answerTextPc, {
            opacity: 0,
            duration: 0,
            overwrite: true,
          });
          gsap.to(answerTextSp, {
            opacity: 0,
            duration: 0,
            overwrite: true,
          });
          line.style.opacity = 1;

          if (Common.isTouchDevice) {
            button.style.background = white;
            number.style.color = red;
            questionPc.style.color = black;
            questionSp.style.color = black;
            gsap.to(buttonTextBox, {
              transform: "translate3d(0rem, 0, 0)",
              duration: 0,
            });
          }
          isOpen = false;
        }
      });
    });

    const anchors = document.querySelectorAll(".faq_card_answer_text a");
    anchors.forEach((anchor) => {
      anchor.addEventListener("mouseenter", () => {
        anchor.style.color = red;
        anchor.style.borderColor = red;
      });
      anchor.addEventListener("mouseleave", () => {
        anchor.style.color = black;
        anchor.style.borderColor = black;
      });
      anchor.addEventListener("click", () => {
        anchor.style.color = black;
        anchor.style.borderColor = black;
        gsap.to(anchor, {
          color: red,
          borderColor: red,
          duration: pulseDuration,
          ease: "pulse",
        });
      });
    });
  }
}

export default new FaqList();
