import gsap from "gsap";
import { black, red } from "../../const/color";
import { pulseDuration } from "../../utils/animation/pulseAnimation";
import Common from "../Common";
import Search from "../Search";

class LocationPage {
  constructor() {}
  init() {
    Search.init();
    this.setAnimation();
  }
  setAnimation() {
    const anchors = document.querySelectorAll(".ward_store_anchor");
    const imgCovers = document.querySelectorAll(".ward_store_imgCover");

    anchors.forEach((anchor, index) => {
      const cover = imgCovers[index];
      const hide = () => {
        gsap.to(cover, {
          opacity: 0,
          duration: 0,
          overwrite: true,
        });
      };

      anchor.addEventListener("mouseenter", () => {
        if (Common.isTouchDevice) return;
        anchor.style.color = red;
        gsap.to(cover, {
          opacity: 1,
          duration: pulseDuration,
          ease: "pulse",
          onComplete: hide,
        });
      });
      anchor.addEventListener("mouseleave", () => {
        anchor.style.color = black;
        gsap.to(cover, {
          opacity: 0,
          duration: 0,
          overwrite: true,
        });
      });
      anchor.addEventListener("click", () => {
        const start = () => {
          gsap.to(anchor, {
            color: red,
            duration: pulseDuration,
            ease: "pulse",
          });
          gsap.to(cover, {
            opacity: 1,
            duration: pulseDuration,
            ease: "pulse",
          });
        };
        gsap.to(anchor, {
          color: black,
          duration: 0,
          overwrite: true,
        });
        gsap.to(cover, {
          opacity: 0,
          duration: 0,
          overwrite: true,
          onComplete: start,
        });
      });
    });
  }
}

export default new LocationPage();
