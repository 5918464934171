import gsap from "gsap";
import CustomEase from "gsap/CustomEase";

// duration
export const defaultDuration = 0.5;
export const longDuration = defaultDuration * 2;
export const longerDuration = defaultDuration * 3;
export const longestDuration = defaultDuration * 4;
export const mostLongestDuration = defaultDuration * 5;
export const shortDuration = defaultDuration * 0.5;
export const shorterDuration = shortDuration * 0.5;
export const shortestDuration = shorterDuration * 0.5;

gsap.registerPlugin(CustomEase);
CustomEase.create(
  "pulse",
  "M0,0 C0,0 0,1 0,1 0,1 0.167,1 0.167,1 0.167,1 0.167,0 0.167,0 0.167,0 0.333,0 0.333,0 0.333,0 0.333,1 0.333,1 0.333,1 0.5,1 0.5,1 0.5,1 0.5,0 0.5,0 0.5,0 0.667,0 0.667,0 0.667,0 0.667,1 0.667,1 0.667,1 0.834,1 0.834,1 0.834,1 0.834,0 0.834,0 0.834,0 1,0 1,0 1,0 1,1 1,1"
);
CustomEase.create(
  "jet",
  "M0,0 C0,0 0,0.866 0.358,0.956 0.512,0.994 0.758,1 1,1"
);

// easing
export const defaultEaseInOut = "Expo.easeInOut";
