import gsap from "gsap";
import { shortDuration } from "../../const/animation";
import Common from "../../components/Common";

export const pulseDuration = shortDuration;

export const mouseEnterPulseAnimation = (target) => {
  if (Common.isTouchDevice) return;
  const after = () => {
    gsap.to(target, {
      opacity: 1,
      duration: 0,
      overwrite: true,
    });
  };
  gsap.to(target, {
    opacity: 0,
    duration: pulseDuration,
    ease: "pulse",
    onComplete: after,
  });
};
export const mouseLeavePulseAnimation = (target) => {
  const after = () => {
    gsap.to(target, {
      opacity: 1,
      duration: 0,
      overwrite: true,
    });
  };
  gsap.to(target, {
    opacity: 0,
    duration: pulseDuration,
    ease: "pulse",
    onComplete: after,
  });
};
export const fadeInPulseAnimation = (target) => {
  gsap.to(target, {
    opacity: 1,
    duration: pulseDuration,
    ease: "pulse",
  });
};
