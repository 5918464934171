import gsap from "gsap";
import Firstview from "../Firstview";
import {
  defaultDuration,
  shortDuration,
  shorterDuration,
} from "../../const/animation.js";
import { spView } from "../../const/breakPoint.js";
import { black, red } from "../../const/color.js";
import {
  onlyArrowButtonEnter,
  onlyArrowButtonLeave,
} from "../../utils/animation/buttonAnimation.js";
import {
  inViewTopAbout,
  inViewTopFlow,
  inViewTopPointPc,
  inViewTopPointSp,
  inviewWideList,
} from "../../utils/animation/inViewAnimation.js";
import { pulseDuration } from "../../utils/animation/pulseAnimation.js";
import {
  fadeInTriangleAnimation,
  inViewTileAnimation,
} from "../../utils/animation/tileAnimation.js";
import { wideListMouseAnmation } from "../../utils/animation/wideListAnimation.js";
import Common from "../Common.js";
import { getWinSize } from "../../utils/getWinSIze";
import { inteviewAnimation } from "../../utils/animation/interviewAnimation";
import { sideArrowButtonAnimation } from "../../utils/animation/sideArrowButtonAnimation";
import ParallaxBg from "../ParallaxBg";

class TopPage {
  constructor() {}
  init() {
    Firstview.init();
    this.setAnimation();
  }
  enterForFirstSession() {
    Firstview.enterForFirstSession();
  }
  enter() {
    Firstview.enter();
  }
  setLocationAnimation() {
    const onlyTextList = document.querySelector(".location_onlyTextLists");
    const withImgList = document.querySelector(".location_withImgLists");
    const withImgCardScreen = document.querySelectorAll(
      ".location_withImgLists_imgScreen"
    );

    const onlyTextCards = document.querySelectorAll(
      ".location_onlyTextLists .wideList_item"
    );
    const withImgCards = document.querySelectorAll(
      ".location_withImgLists_card"
    );
    const listButton = document.querySelector(
      ".location_header_toggleListButon.--list"
    );
    const visualButton = document.querySelector(
      ".location_header_toggleListButon.--visual"
    );
    const listButtonText = document.querySelector(
      ".location_header_toggleListButon_text.--list"
    );
    const visualButtonText = document.querySelector(
      ".location_header_toggleListButon_text.--visual"
    );
    const listButtonTriangle = document.querySelector(
      ".location_header_toggleListButon_triangle.--list"
    );
    const visualButtonTriangle = document.querySelector(
      ".location_header_toggleListButon_triangle.--visual"
    );
    // リストの切り替え処理
    const offButton = (text, arrow) => {
      gsap.to(text, {
        opacity: 0.32,
        duration: 0,
      });
      gsap.to(arrow, {
        opacity: 0,
        duration: 0,
        overwrite: true,
      });
    };
    const onButton = (text, arrow) => {
      gsap.to(text, {
        opacity: 1,
        duration: 0,
      });
      fadeInTriangleAnimation(arrow);
    };

    const offList = (cards) => {
      const interval = defaultDuration * 0.025;
      cards.forEach((card, index) => {
        setTimeout(() => {
          const start = () => {
            gsap.to(card, {
              opacity: 1,
              duration: shortDuration,
              ease: "jet",
            });
          };
          gsap.to(card, {
            opacity: 0,
            duration: 0,
            overwrite: true,
            onComplete: start,
          });
        }, interval * index * 1000);
      });
    };
    const onList = (cards) => {
      cards.forEach((card) => {
        gsap.to(card, {
          opacity: 0,
          duration: 0,
          overwrite: true,
        });
      });
    };

    listButton.addEventListener("click", () => {
      listButton.classList.add("js--noClick");
      visualButton.classList.remove("js--noClick");
      onButton(listButtonText, listButtonTriangle);
      offButton(visualButtonText, visualButtonTriangle);
      onlyTextList.classList.remove("js--none");
      withImgList.classList.add("js--none");
      offList(onlyTextCards);
      onList(withImgCards);
    });
    visualButton.addEventListener("click", () => {
      visualButton.classList.add("js--noClick");
      listButton.classList.remove("js--noClick");
      onButton(visualButtonText, visualButtonTriangle);
      offButton(listButtonText, listButtonTriangle);
      onlyTextList.classList.add("js--none");
      withImgList.classList.remove("js--none");
      offList(withImgCards);
      onList(onlyTextCards);
    });

    // cardのhoverイベント
    withImgCards.forEach((card, index) => {
      card.addEventListener("mouseenter", () => {
        if (Common.isTouchDevice) return;
        gsap.to(card, {
          color: red,
          duration: 0,
        });
        const start = () => {
          gsap.to(withImgCardScreen[index], {
            opacity: 0,
            duration: 0,
            overwrite: true,
          });
        };
        gsap.to(withImgCardScreen[index], {
          opacity: 1,
          duration: pulseDuration,
          ease: "pulse",
          overwrite: true,
          onComplete: start,
        });
      });
      card.addEventListener("mouseleave", () => {
        gsap.to(card, {
          color: black,
          duration: 0,
        });
        gsap.to(withImgCardScreen[index], {
          opacity: 0,
          duration: 0,
          overwrite: true,
        });
      });
      card.addEventListener("click", () => {
        const start = () => {
          gsap.to(card, {
            color: black,
            duration: pulseDuration,
            ease: "pulse",
          });
        };
        gsap.to(card, {
          color: red,
          duration: 0,
          overwrite: true,
          onComplete: start,
        });
      });
    });

    const locationAnchors = document.querySelectorAll(".location_anchor");
    const locationAnchorArrows = document.querySelectorAll(
      ".location_anchor_arrow"
    );
    locationAnchors.forEach((locationAnchor, index) => {
      locationAnchor.addEventListener("mouseenter", () => {
        onlyArrowButtonEnter(locationAnchorArrows[index]);
      });
      locationAnchor.addEventListener("click", () => {
        const start = () => {
          gsap.to(locationAnchorArrows[index], {
            opacity: 1,
            duration: pulseDuration,
            ease: "pulse",
          });
        };
        gsap.to(locationAnchorArrows[index], {
          opacity: 0,
          duration: 0,
          overwrite: true,
          onComplete: start,
        });
      });
    });
  }
  setInterviewAnimation() {
    inteviewAnimation(
      ".interview_anchor",
      ".interview_arrow",
      ".interview_triangle",
      ".interview_arrowInner"
    );
  }
  setNewsAnimation() {
    const newsCards = document.querySelectorAll(".news_newsList_anchor");
    const newsImgs = document.querySelectorAll(".news_newsList_greyBox");
    const newsTitles = document.querySelectorAll(".news_newsList_title");
    newsCards.forEach((card, index) => {
      card.addEventListener("mouseenter", () => {
        if (Common.isTouchDevice) return;
        gsap.to(newsImgs[index], {
          transform: "scale(0.972, 0.956)",
          duration: defaultDuration,
          ease: "jet",
        });
        gsap.to(newsTitles[index], {
          color: red,
          duration: 0,
        });
      });
      card.addEventListener("mouseleave", () => {
        gsap.to(newsImgs[index], {
          transform: "scale(1, 1)",
          duration: defaultDuration,
          ease: "jet",
        });
        gsap.to(newsTitles[index], {
          color: black,
          duration: 0,
        });
      });
      card.addEventListener("click", () => {
        const start = () => {
          gsap.to(newsTitles[index], {
            color: black,
            duration: pulseDuration,
            ease: "pulse",
          });
        };
        gsap.to(newsTitles[index], {
          color: red,
          duration: 0,
          overwrite: true,
          onComplete: start,
        });
      });
    });

    const tags = document.querySelectorAll(".news_newsList_tagAnchor");
    tags.forEach((tag) => {
      tag.addEventListener("mouseenter", () => {
        if (Common.isTouchDevice) return;
        gsap.to(tag, {
          opacity: 0.75,
          duration: 0,
        });
      });
      tag.addEventListener("mouseleave", () => {
        gsap.to(tag, {
          opacity: 1,
          duration: 0,
        });
      });
      tag.addEventListener("click", () => {
        const start = () => {
          gsap.to(tag, {
            opacity: 1,
            duration: pulseDuration,
            ease: "pulse",
          });
        };
        gsap.to(tag, {
          opacity: 0.75,
          duration: 0,
          overwrite: true,
          onComplete: start,
        });
      });
    });
  }
  setAboutCoronaAnimation() {
    sideArrowButtonAnimation(
      ".aboutCorona_anchor",
      ".aboutCorona_bg",
      ".aboutCorona_triangleBox.--right",
      ".aboutCorona_triangleBox.--left",
      ".aboutCorona_text",
      4000,
      100
    );
  }
  setScrollAnimation() {
    const about = document.querySelector(".about");
    const aboutText = document.querySelector(".about_imgs_textCover");
    let isAboutWithIn = false;
    const judgeIsAboutWithIn = (entry) =>
      (isAboutWithIn = entry[0].isIntersecting);
    const aboutObserver = new IntersectionObserver(judgeIsAboutWithIn);
    aboutObserver.observe(about);

    const training = document.querySelector(".training");
    const trainingImg = document.querySelector(".training_img");
    let isTrainingWithIn = false;
    const judgeIsTrainingWithIn = (entry) =>
      (isTrainingWithIn = entry[0].isIntersecting);
    const trainingObserver = new IntersectionObserver(judgeIsTrainingWithIn);
    trainingObserver.observe(training);

    const policy = document.querySelector(".policy");
    const policyImg = document.querySelector(".policy_img");
    let isPolicyWithIn = false;
    const judgeIsPolicyWithIn = (entry) =>
      (isPolicyWithIn = entry[0].isIntersecting);
    const policyObserver = new IntersectionObserver(judgeIsPolicyWithIn);
    policyObserver.observe(policy);

    const anchor = document.querySelector("body");

    const startSlideY = (container, target) => {
      const deg =
        1 - (container.getBoundingClientRect().top / anchor.clientHeight + 0.5);
      const roundDeg = Math.round(deg * 100) / 100;

      gsap.to(target, {
        yPercent: (roundDeg - 0.5) * -10,
        duration: shorterDuration,
        ease: "Power1.easeOut",
        overwrite: true,
      });
    };

    const aboutTextBox = document.querySelectorAll(".about_itemBox");
    const startAbout = (container, text) => {
      const deg =
        1 - (container.getBoundingClientRect().top / anchor.clientHeight + 0.5);
      const roundDeg = Math.round(deg * 100) / 100;
      gsap.to(text, {
        yPercent: (roundDeg - 0.5) * -20,
        duration: shorterDuration,
        ease: "Power1.easeOut",
        overwrite: true,
      });
      const winSize = getWinSize();
      const dist = winSize <= spView ? 0 : (roundDeg - 0.5) * -50;
      aboutTextBox.forEach((item) => {
        gsap.to(item, {
          yPercent: dist * 0.5,
          duration: shorterDuration,
          ease: "Power1.easeOut",
          overwrite: true,
        });
      });
    };

    window.addEventListener("scroll", () => {
      if (isTrainingWithIn) startSlideY(training, trainingImg);
      if (isAboutWithIn) startAbout(about, aboutText);
      if (isPolicyWithIn) startSlideY(policy, policyImg);
    });
  }
  setInviewAnimation() {
    inViewTileAnimation(".about_titles");
    inViewTileAnimation(".training_title");
    inViewTileAnimation(".policy_title");
    inviewWideList(".location_body");
    inviewWideList(".news_list");
    inviewWideList(".interview_linkList");

    inViewTopAbout();
    inViewTopFlow();
    inViewTopPointPc();
    inViewTopPointSp();
  }
  setMouseAnimation() {
    wideListMouseAnmation(".location_wideList.--tokyo");
    wideListMouseAnmation(".location_wideList.--osaka");
    wideListMouseAnmation(".interview_linkList");
    wideListMouseAnmation(".news_list");
  }
  setAnimation() {
    this.setAboutCoronaAnimation();
    this.setLocationAnimation();
    this.setInterviewAnimation();
    this.setNewsAnimation();

    this.setScrollAnimation();
    this.setInviewAnimation();
    this.setMouseAnimation();
  }
  resize() {
    Firstview.resize();
  }
}

export default new TopPage();
