import { gsap } from "gsap";
import Common from "../../components/Common";
import { black, grey, red } from "../../const/color";
import { mouseEnterPulseAnimation, pulseDuration } from "./pulseAnimation";

export const inteviewAnimation = (anchor, arrow, triangle, arrowInner) => {
  const buttons = document.querySelectorAll(anchor);
  const arrows = document.querySelectorAll(arrow);
  const triangles = document.querySelectorAll(triangle);
  const arrowInners = document.querySelectorAll(arrowInner);
  buttons.forEach((button, index) => {
    const arrow = arrows[index];
    const triangle = triangles[index];
    const arrowInner = arrowInners[index];
    button.addEventListener("mouseenter", () => {
      if (Common.isTouchDevice) return;
      gsap.to(button, {
        color: red,
        duration: 0,
      });
      gsap.to(arrow, {
        backgroundColor: grey,
        duration: 0,
      });
      mouseEnterPulseAnimation(triangle);
    });
    button.addEventListener("mouseleave", () => {
      gsap.to(button, {
        color: black,
        duration: 0,
      });
      gsap.to(arrow, {
        backgroundColor: black,
        duration: 0,
      });
    });
    button.addEventListener("click", () => {
      const start = () => {
        gsap.to(button, {
          color: black,
          duration: pulseDuration,
          ease: "pulse",
        });
        gsap.to(arrow, {
          backgroundColor: red,
          duration: pulseDuration,
          ease: "pulse",
        });
      };
      arrowInner.classList.add("js--none");
      gsap.to(arrow, {
        backgroundColor: grey,
        duration: 0,
        overwrite: true,
      });
      gsap.to(button, {
        color: red,
        duration: 0,
        overwrite: true,
        onComplete: start,
      });
    });
  });
};
