import gsap from "gsap";
import {
  defaultDuration,
  mostLongestDuration,
  shortDuration,
} from "../../const/animation";
import { pulseDuration } from "../../utils/animation/pulseAnimation";
import Common from "../Common";
import { getWinSize } from "../../utils/getWinSIze";
import { fadeInTriangleAnimation } from "../../utils/animation/tileAnimation";
import { spView } from "../../const/breakPoint";
import { wait } from "../../utils/wait";

class StaffDetailsPage {
  constructor() {
    this.maxLen = 2;
    this.total = 0;
    this.isSlide = true;

    this.current = 0;

    this.startTime = 0;
    this.fpsRate = 0;
    this.step = 0;

    this.noClick = false;

    this.isShow = false;

    this.isRunning = false;

    this.fadeArr_5_5 = [
      [0],
      [1, 5],
      [2, 6, 10],
      [3, 7, 11, 15],
      [4, 8, 12, 16, 20],
      [9, 13, 17, 21],
      [14, 18, 22],
      [19, 23],
      [24],
    ];
  }
  init() {
    this.setMouseAnimation();
    this.setSlider();
  }
  setMouseAnimation() {
    const setIcon = () => {
      const targets = document.querySelectorAll(".icon");
      const reds = document.querySelectorAll(".icon_img.--red");
      const blacks = document.querySelectorAll(".icon_img.--black");
      targets.forEach((target, index) => {
        const red = reds[index];
        const black = blacks[index];
        target.addEventListener("mouseenter", () => {
          if (Common.isTouchDevice) return;
          red.style.opacity = "1";
          black.style.opacity = "0";
        });
        target.addEventListener("mouseleave", () => {
          red.style.opacity = "0";
          black.style.opacity = "1";
        });
      });
    };
    const setTag = () => {
      const targets = document.querySelectorAll(".staffDetailPage .js--tag");
      targets.forEach((target) => {
        target.addEventListener("mouseenter", () => {
          if (Common.isTouchDevice) return;
          gsap.to(target, {
            opacity: 0.75,
            duration: shortDuration,
            ease: "jet",
          });
        });
        target.addEventListener("mouseleave", () => {
          gsap.to(target, {
            opacity: 1,
            duration: shortDuration,
            ease: "jet",
          });
        });
        target.addEventListener("click", () => {
          const start = () => {
            gsap.to(target, {
              opacity: 1,
              duration: pulseDuration,
              ease: "pulse",
            });
          };
          gsap.to(target, {
            opacity: 0.75,
            duration: 0,
            onComplete: start,
          });
        });
      });
    };
    setIcon();
    setTag();
  }
  setUpSlider() {
    const items = document.querySelectorAll(".slider_item");
    const scales = document.querySelectorAll(".slider_scale_item");
    const trianglesPc = document.querySelector(".slider_scale_arrow");

    const firstItem = document.querySelector(".slider_item");
    const firstFrontImg = document.querySelector(".slider_item_frontImg");
    const firstSlide = document.querySelector(".slider_item_tileContainer");
    firstItem.style.zIndex = 2;
    firstFrontImg.style.opacity = 1;
    const tileCount = firstSlide.childElementCount;
    [...Array(tileCount)].forEach((_, index) => {
      firstSlide.children[index].style.opacity = 1;
    });

    // 画像が1枚以下だった場合にスライドを発火させない
    if (items.length <= 1) {
      const scale = document.querySelector(".slider_scale_items");
      const carousel = document.querySelector(".slider_carousels");
      scale.classList.add("js--none");
      carousel.classList.add("js--none");
      firstSlide.classList.add("js--none");

      this.isSlide = false;
      return;
    }

    const button = document.querySelector(".slider_carousel");
    button.classList.add("js--none");

    trianglesPc.style.opacity = 1;
    this.total = items.length - 1;
    [...Array(this.maxLen)].forEach((_, index) => {
      if (index > this.total && scales[index])
        scales[index].classList.add("js--none");
    });
  }
  updateTimeRatio() {
    const lastTime = this.startTime;
    if (lastTime > 0) {
      const fps60Sec = 1000 / 60;
      const dTime = new Date().getTime() - lastTime;
      this.fpsRate = Math.round((dTime / fps60Sec) * 100) / 100;
    }
    this.startTime = new Date().getTime();
  }
  changeSlide(next) {
    if (!this.isSlide) return;

    this.noClick = true;

    const before = this.current;
    this.current = next;
    this.step = this.current;

    // タイル状の画像を段階的に消す
    const tileInt =
      ((defaultDuration - defaultDuration * 0.2) / this.fadeArr_5_5.length) *
      1000;
    const startTileAnimation = (tiles, buttons) => {
      const fadeOutTile = () => {
        this.fadeArr_5_5.forEach((item, index) => {
          setTimeout(() => {
            item.forEach((number) => {
              tiles[number].style.opacity = 0;
            });
            if (index === this.fadeArr_5_5.length - 1) {
              this.noClick = false;
              buttons.forEach((button) => {
                button.classList.remove("js--noClick");
              });
            }
          }, index * tileInt);
        });
      };
      fadeOutTile();
    };

    const change = (cards, tileBox, frontImg, triangles, buttons) => {
      const tileCount = tileBox[before].childElementCount;
      const tilesBofore = [...Array(tileCount)].map((_, index) => {
        return tileBox[before].children[index];
      });
      const tilesCurrent = [...Array(tileCount)].map((_, index) => {
        return tileBox[this.current].children[index];
      });

      wait(0)
        .then(() => {
          // 前準備
          frontImg[before].style.opacity = 0;
          cards[before].style.zIndex = 2;
          cards[this.current].style.zIndex = 0;
          cards[this.current].style.opacity = 1;
          frontImg[this.current].style.opacity = 1;
          triangles.forEach((triangle) => {
            gsap.to(triangle, {
              opacity: 0,
              duration: 0,
              overwrite: true,
            });
          });
          tilesCurrent.forEach((tile) => {
            tile.style.opacity = 1;
          });
          buttons.forEach((button, index) => {
            button.classList.add("js--noClick");

            if (index === this.current) button.classList.add("js--none");
            else button.classList.remove("js--none");
          });

          return wait(0);
        })
        .then(() => {
          startTileAnimation(tilesBofore, buttons);
          const scale = () => {
            gsap.to(cards[this.current], {
              scale: 1,
              ease: "jet",
              duration: mostLongestDuration,
            });
          };
          gsap.to(cards[this.current], {
            scale: 1.1,
            duration: 0,
            overwrite: true,
            onComplete: scale,
          });
          fadeInTriangleAnimation(triangles[this.current]);
        });
    };

    const tileBoxPc = document.querySelectorAll(".slider_item_tileContainer");
    const frontImgsPc = document.querySelectorAll(".slider_item_frontImg");
    const trianglesPc = document.querySelectorAll(".slider_scale_arrow");
    const cardsPc = document.querySelectorAll(".slider_item");
    const buttons = document.querySelectorAll(".slider_carousel");
    change(cardsPc, tileBoxPc, frontImgsPc, trianglesPc, buttons);
  }
  startSlider() {
    if (!this.isSlide) return;

    this.isRunning = true;
    this.startTime = 0;
    this.fpsRate = 1;

    const scalesPc = document.querySelector(".slider_scale_items");
    const scalePc = document.querySelector(".slider_scale_item");
    const progressBarPc = document.querySelector(
      ".slider_scale_progress.--front"
    );

    const update = () => {
      this.updateTimeRatio();
      this.step += 0.003 * this.fpsRate;

      const winSize = getWinSize();
      if (winSize > spView) {
        const degreePc = Math.round(
          (scalePc.clientHeight / 100) * this.step * 100
        );
        const progressPc = degreePc / scalesPc.clientHeight;
        progressBarPc.style.transform = `scale(1, ${progressPc})`;
      } else {
        const degreePc = Math.round(
          (scalePc.clientWidth / 100) * this.step * 100
        );
        const progressPc = degreePc / scalesPc.clientWidth;
        progressBarPc.style.transform = `scale(${progressPc}, 1)`;
      }

      const current = Math.floor(this.step % (this.total + 1));
      if (this.current !== current) {
        const next = this.current >= this.total ? 0 : this.current + 1;
        this.changeSlide(next);
      }
      if (this.isRunning) window.requestAnimationFrame(update);
    };
    window.requestAnimationFrame(update);
  }
  toggleSlideByScroll() {
    const target = document.querySelector(".body");
    const start = (entry) => {
      entry[0].isIntersecting ? this.startSlider() : (this.isRunning = false);
      entry[0].isIntersecting ? (this.isShow = true) : (this.isShow = false);
    };
    const observer = new IntersectionObserver(start);
    observer.observe(target);
  }
  toggleSlideByFocus() {
    window.addEventListener("focus", () => {
      if (this.isRunning === false && this.isShow) this.startSlider();
    });
    window.addEventListener("blur", () => {
      this.isRunning = false;
    });
  }
  setClickEvent() {
    const targets = document.querySelectorAll(".slider_carousel_button");
    targets.forEach((target, index) => {
      target.addEventListener("click", () => {
        if (index === this.current || this.noClick) return;
        else {
          this.changeSlide(index);
        }
      });
    });
  }
  setSlider() {
    this.setUpSlider();
    this.setClickEvent();
    this.toggleSlideByScroll();
    this.toggleSlideByFocus();
  }
}

export default new StaffDetailsPage();
