import Common from "./components/Common";
import TopPage from "./components/pages/TopPgae";
import MethodPage from "./components/pages/MethodPage";
import TrainingPage from "./components/pages/TrainingPage";
import LocationPage from "./components/pages/LocationPage";
import ContactPage from "./components/pages/ContactPage";
import ArticlePage from "./components/pages/ArticlePage";
import ContactFormPage from "./components/pages/ContactFormPage";
import NewsPage from "./components/pages/NewsPage";
import CampaignPage from "./components/pages/CampaignPage";
import MediaPage from "./components/pages/MediaPage";
import StaffPage from "./components/pages/StaffPage";
import StaffDetailsPage from "./components/pages/StaffDetailsPage";
import { shortDuration, shorterDuration } from "./const/animation";
import CompanyPage from "./components/pages/CompanyPage";
import ReviewPage from "./components/pages/ReviewPage";
import ReviewDetailsPage from "./components/pages/ReviewDetailsPage";
import InterviewDetailsPage from "./components/pages/InterviewDetailsPage";
import LocationDetailsPage from "./components/pages/LocationDetailsPage";
import FaqPage from "./components/pages/FaqPage";
import TrainingIndexPage from "./components/pages/TrainingIndexPage";
import PricePage from "./components/pages/PricePage";

class App {
  constructor() {
    this.pageContent = null;
    this.init();
  }
  init() {
    Common.init();
    this.pageContent = document.querySelector("#__pageContent");
    if (this.pageContent.classList.contains("topPage")) TopPage.init();
    else if (this.pageContent.classList.contains("methodPage"))
      MethodPage.init();
    else if (this.pageContent.classList.contains("trainingPage"))
      TrainingPage.init();
    else if (this.pageContent.classList.contains("locationPage"))
      LocationPage.init();
    else if (this.pageContent.classList.contains("contactPage"))
      ContactPage.init();
    else if (this.pageContent.classList.contains("articlePage"))
      ArticlePage.init();
    else if (this.pageContent.classList.contains("contactFromPage"))
      ContactFormPage.init();
    else if (this.pageContent.classList.contains("newsPage")) NewsPage.init();
    else if (this.pageContent.classList.contains("campaignPage"))
      CampaignPage.init();
    else if (this.pageContent.classList.contains("mediaPage")) MediaPage.init();
    else if (this.pageContent.classList.contains("staffPage")) StaffPage.init();
    else if (this.pageContent.classList.contains("staffDetailPage"))
      StaffDetailsPage.init();
    else if (this.pageContent.classList.contains("companyPage"))
      CompanyPage.init();
    else if (this.pageContent.classList.contains("reviewPage"))
      ReviewPage.init();
    else if (this.pageContent.classList.contains("reviewDetailPage"))
      ReviewDetailsPage.init();
    else if (this.pageContent.classList.contains("interviewDetailPage"))
      InterviewDetailsPage.init();
    else if (this.pageContent.classList.contains("locationDetailsPage"))
      LocationDetailsPage.init();
    else if (this.pageContent.classList.contains("faqPage")) FaqPage.init();
    else if (this.pageContent.classList.contains("trainingIndexPage"))
      TrainingIndexPage.init();
    else if (this.pageContent.classList.contains("pricePage")) PricePage.init();

    this.setEvent();
    this.enter();
  }
  enter() {
    setTimeout(() => {
      const body = document.querySelector("body");
      body.style.opacity = 1;
      if (Common.isFirstSession) {
        Common.enterForFirstSession();
        if (this.pageContent.classList.contains("topPage"))
          TopPage.enterForFirstSession();
      } else {
        Common.enter();
        if (this.pageContent.classList.contains("topPage")) TopPage.enter();
      }
    }, shortDuration * 1000);
  }
  setEvent() {
    window.addEventListener("resize", this.resize.bind(this));
  }
  resize() {
    Common.resize();
    if (this.pageContent.classList.contains("topPage")) TopPage.resize();
    else if (this.pageContent.classList.contains("methodPage"))
      MethodPage.resize();
    else if (this.pageContent.classList.contains("trainingPage"))
      MethodPage.resize();
  }
}

// 戻るボタンによるページ遷移の場合に、キャッシュを無効にする
window.onbeforeunload = function () {};
window.onunload = function () {};
window.onpageshow = function (event) {
  // 戻るボタンによる遷移の場合は、jsを初期化するためにリロードを挟む
  if (event.persisted) window.location.reload();
  new App();
};
