import gsap from "gsap";
import { defaultDuration, shortDuration } from "../const/animation";
import { spView } from "../const/breakPoint";
import { fadeOutTileAnimation } from "../utils/animation/tileAnimation";
import { getWinSize } from "../utils/getWinSIze";
import Common from "./Common";
import { pulseDuration } from "../utils/animation/pulseAnimation";

class Loading {
  constructor() {
    this.fadeArr_18_9 = [
      [0],
      [1, 19, 18, 37, 21, 3, 41],
      [20, 23, 6, 7, 38, 56, 57, 59, 72, 75, 40, 42],
      [55, 58, 22, 5, 25, 26, 41, 44, 45, 60, 78, 76, 73, 77, 74],
      [
        2, 36, 62, 63, 79, 81, 97, 94, 93, 91, 111, 117, 66, 10, 28, 12, 30, 13,
        132, 39, 100, 101, 118, 43,
      ],
      [
        4, 24, 54, 8, 61, 92, 119, 128, 146, 113, 131, 149, 167, 165, 163, 86,
        123, 126, 108, 129, 130, 147, 178, 155, 153, 35, 138, 109, 46,
      ],
      [
        95, 96, 98, 80, 114, 115, 116, 127, 134, 135, 137, 65, 29, 27, 32, 15,
        17, 51, 70, 71, 87, 144, 90, 145, 166, 150, 151, 170, 172, 103, 64, 139,
        175, 174, 83, 118, 50,
      ],
      [
        110, 162, 164, 148, 168, 169, 171, 99, 47, 9, 11, 49, 68, 16, 34, 124,
        142, 143, 177, 157, 83, 52, 107, 125, 112, 112, 133, 152, 136, 173, 82,
        31, 14, 33, 140,
      ],
      [
        112, 133, 152, 136, 173, 82, 31, 48, 69, 53, 88, 106, 104, 121, 120, 84,
        67, 85, 102, 154, 156, 158, 160, 161, 176, 105, 122, 141, 159,
      ],
      [89, 179],
    ];

    this.fadeArr_18_18 = [
      [0],
      [
        1, 19, 18, 37, 21, 3, 41, 20, 23, 6, 7, 38, 56, 57, 59, 72, 75, 40, 42,
        55, 58, 22, 5, 25, 26, 41, 44, 45, 60, 78, 76, 73, 77, 74,
      ],
      [
        2, 36, 62, 63, 79, 81, 97, 94, 93, 91, 111, 117, 66, 10, 28, 12, 30, 13,
        132, 39, 100, 101, 118, 43, 4, 24, 54, 8, 61, 92, 119, 128, 146, 113,
        131, 149, 167, 165, 163, 86, 123, 126, 108, 129, 130, 147, 155, 153, 35,
        138, 109, 46, 95, 96, 98, 80, 114, 115, 116, 127, 134, 135,
      ],
      [
        110, 162, 164, 148, 168, 169, 99, 47, 9, 11, 49, 68, 16, 34, 143, 157,
        83, 52, 125, 112, 112, 133, 152, 136, 82, 31, 14, 33, 140, 112, 133,
        152, 136, 82, 31, 48, 69, 53, 88, 106, 104, 121, 120, 84, 67, 85, 102,
        154, 158, 160, 105, 122, 89, 180, 182, 217, 234, 270, 219, 254, 272,
        289, 202, 203, 185, 186, 171, 238, 253, 306,
      ],
      [
        200, 201, 198, 235, 184, 183, 151, 221, 222, 223, 205, 241, 276, 274,
        292, 207, 190, 191, 174, 260, 277, 294, 311, 310, 308, 307, 27, 29, 32,
        64, 142, 159, 255, 273, 271, 216, 181, 144, 257, 275, 90, 188, 223, 240,
        278, 312, 309, 288, 246, 296, 228, 292, 156, 178, 179, 250, 243, 145,
        199, 218, 17,
      ],
      [
        15, 51, 50, 65, 103, 71, 70, 87, 150, 166, 252, 290, 293, 236, 225, 226,
        261, 315, 313, 318, 299, 237, 204, 206, 279, 262, 263, 227, 220, 170,
        291, 295, 280, 281, 256, 171, 209, 210, 194, 176, 177, 172, 137, 187,
        239, 258, 195, 221, 267, 297, 316, 319, 161, 211, 214, 230, 247, 245,
        232,
      ],
      [
        107, 124, 141, 139, 175, 193, 192, 173, 189, 208, 224, 231, 304, 322,
        301, 212, 196, 197, 269, 264, 244, 242, 266, 248, 282, 300, 298, 314,
        317, 229, 259, 265, 251, 233, 215, 213,
      ],
      [
        247, 265, 245, 259, 297, 316, 285, 249, 268, 286, 285, 284, 302, 285,
        249, 302, 249, 302, 321, 283, 305,
      ],
      [287, 303, 320],
      [323],
    ];
    this.isAnimation = true;

    this.oneWayDuration = defaultDuration;
    this.tileFadedDuration = defaultDuration * 0.1;
    this.interval = 50;
  }
  init() {
    this.setsize();
  }
  enterForFirstSession() {
    // 右下のカウンターの数値を変更する
    const progressCounter = document.querySelector(".loading_progressCounter");
    const finish = () => {
      this.isAnimation = false;
    };
    gsap.to(progressCounter, {
      x: "100vw",
      duration: defaultDuration,
      ease: "power1.easeInOut",
      onComplete: finish,
    });
    const loadingCounter = document.querySelector(".loading_counterText");
    const updateCounter = (count) => {
      const zeroPaddingCount = ("000" + count).slice(-3);
      loadingCounter.innerHTML = zeroPaddingCount;
    };
    const update = () => {
      const rect = progressCounter.getBoundingClientRect();
      const count = Math.round((rect.left / window.innerWidth) * 100);
      updateCounter(count);
      this.isAnimation
        ? window.requestAnimationFrame(update)
        : updateCounter(100);
    };
    window.requestAnimationFrame(update);

    // タイルを段階的に出現させる
    const int =
      ((defaultDuration - defaultDuration * 0.1) / this.fadeArr_18_18.length) *
      1000;
    const tiles = document.querySelectorAll(".loading_tile");
    const fadeInTile = () => {
      this.fadeArr_18_18.forEach((item, index) => {
        setTimeout(() => {
          item.forEach((number) => {
            tiles[number].style.opacity = 1;
          });
        }, index * int);
      });
    };
    const fadeOutTile = () => {
      this.fadeArr_18_18.forEach((item, index) => {
        setTimeout(() => {
          item.forEach((number) => {
            tiles[number].style.opacity = 0;
          });
        }, index * int);
      });
    };

    const body = document.querySelector("body");
    body.style.visibility = "visible";
    body.style.opacity = 1;
    const duration = defaultDuration;
    fadeInTile();
    setTimeout(() => {
      fadeOutTile();
      fadeOutTileAnimation(loadingCounter);
      const bg = document.querySelector(".loading_bg");
      fadeOutTileAnimation(bg);
    }, duration * 1000);
    setTimeout(() => {
      const loading = document.querySelector(".loading");
      loading.classList.add("js--none");
      body.style.overflowY = "auto";
    }, duration * 2000);
  }
  enter() {
    const body = document.querySelector("body");
    const loading = document.querySelector(".loading");
    loading.classList.add("js--none");
    body.style.overflowY = "auto";
    body.style.visibility = "visible";
    gsap.to(body, {
      opacity: 1,
      duration: shortDuration,
      ease: "jet",
    });
  }
  setsize() {
    const testTileBox = document.querySelector(".loading_tileBox");
    const tiles = document.querySelectorAll(".loading_tile");
    const viewContainer = document.querySelector(".loading_viewArea");
    const viewArea = viewContainer.getBoundingClientRect();
    const body = document.querySelector("body");
    const counter = document.querySelector(".loading_counter");

    // tileBoxが常に画面サイズより大きくなるように調整
    const winW = body.clientWidth;
    const winH = body.clientHeight;
    if (winW > winH) {
      testTileBox.style.height = `${winW * 0.95}px`;
      testTileBox.style.width = `${winW * 0.95}px`;
    } else {
      testTileBox.style.height = `${winH * 0.95}px`;
      testTileBox.style.width = `${winH * 0.95}px`;
    }

    const tileSize = testTileBox.clientHeight / 18;

    // tileが縦横それぞれ何個入るのかを計算
    const tileColumn = Math.floor(viewArea.height / tileSize);
    const tileRow = Math.floor(viewArea.width / tileSize);

    // 縦横それぞれのtileの数が奇数だった場合に、余白を調整する
    tiles.forEach((tile) => {
      if (tileColumn % 2 === 0) {
        tile.style.top = "0px";
      } else {
        tile.style.top = `-${tileSize / 2}px`;
      }
      if (tileRow % 2 === 1) {
        tile.style.left = `-${tileSize / 2}px`;
      } else {
        tile.style.left = "0px";
      }
    });

    // 表示エリア外に出てしまったtileを非表示にする
    tiles.forEach((tile) => {
      const rect = tile.getBoundingClientRect();
      if (
        rect.top < viewArea.top ||
        rect.bottom > viewArea.bottom ||
        rect.left < viewArea.left ||
        rect.right > viewArea.right
      ) {
        tile.style.visibility = "hidden";
      } else {
        tile.style.visibility = "visible";
      }
    });

    // counterのサイズを決定
    const winSize = getWinSize();
    const counterSizeRate = winSize <= spView ? 2 : 1;
    counter.style.height = `${tileSize * counterSizeRate}px`;
    counter.style.width = `${tileSize * counterSizeRate}px`;

    // counterの位置を決定
    tiles.forEach((tile) => {
      const rect = tile.getBoundingClientRect();
      if (
        rect.right <= viewArea.right &&
        rect.bottom <= viewArea.bottom &&
        rect.right >= viewArea.right - tileSize &&
        rect.bottom >= viewArea.bottom - tileSize
      ) {
        const rect = tile.getBoundingClientRect();
        counter.style.right = `${winW - rect.right}px`;
        counter.style.bottom = `${winH - rect.bottom}px`;
      }
    });
  }
  resize() {}
}
export default new Loading();
