import { gsap } from "gsap";
import Common from "../Common";
import PageNation from "../PageNation";
import Search from "../Search";
import StaffList from "../StaffList";
import { pulseDuration } from "../../utils/animation/pulseAnimation";
import SearchCombined from "../SearchCombined";

class StaffPage {
  constructor() {}
  init() {
    PageNation.init();
    StaffList.init();
    Search.init();
    SearchCombined.init();
    this.setPopup();
  }
  setPopup() {
    const openButton = document.querySelector(".pupup_openButton");
    const openButton_arrow = document.querySelector(".pupup_openButton_arrow");
    const popup = document.querySelector(".popup");
    const bg = document.querySelector(".popup_bg");
    const container = document.querySelector(".popup_container");
    const inner = document.querySelector(".popup_inner");
    const closeButton = document.querySelector(".popup_container");

    openButton.addEventListener("mouseenter", () => {
      if (Common.isTouchDevice) return;
      const start = () => {
        gsap.to(openButton_arrow, {
          opacity: 1,
          duration: pulseDuration,
          ease: "pulse",
        });
      };
      gsap.to(openButton_arrow, {
        opacity: 0,
        duration: 0,
        overwrite: true,
        onComplete: start,
      });
    });

    openButton.addEventListener("click", () => {
      popup.style.display = "flex";
      bg.style.opacity = "0.4";
      container.style.opacity = "1";
      gsap.to(inner, {
        opacity: 1,
        duration: pulseDuration,
        ease: "pulse",
        overwrite: true,
      });
    });

    closeButton.addEventListener("click", () => {
      popup.style.display = "none";
      bg.style.opacity = "0";
      container.style.opacity = "0";
      gsap.to(inner, {
        opacity: 0,
        duration: 0,
        overwrite: true,
      });
    });
  }
}

export default new StaffPage();
