import gsap from "gsap";
import { shortDuration } from "../../const/animation";
import { black, red } from "../../const/color";
import { pulseDuration } from "../../utils/animation/pulseAnimation";
import Common from "../Common";
import NewsList from "../NewsList";
import PageNation from "../PageNation";
import Search from "../Search";

class MediaPage {
  constructor() {}
  init() {
    PageNation.init();
    NewsList.init();
    Search.init();
    this.setMouseAnimation();
  }
  setMouseAnimation() {
    const setBunner = () => {
      const targets = document.querySelectorAll(".banner_anchor");
      targets.forEach((target) => {
        target.addEventListener("mouseenter", () => {
          if (Common.isTouchDevice) return;
          gsap.to(target, {
            opacity: 0.75,
            duration: shortDuration,
            ease: "jet",
          });
        });
        target.addEventListener("mouseleave", () => {
          gsap.to(target, {
            opacity: 1,
            duration: shortDuration,
            ease: "jet",
          });
        });
        target.addEventListener("click", () => {
          const start = () => {
            gsap.to(target, {
              opacity: 1,
              duration: pulseDuration,
              ease: "pulse",
            });
          };
          gsap.to(target, {
            opacity: 0.75,
            duration: 0,
            overwrite: true,
            onComplete: start,
          });
        });
      });
    };
    const setList = () => {
      const targets = document.querySelectorAll(".popularArticle_anchor");
      targets.forEach((target) => {
        target.addEventListener("mouseenter", () => {
          if (Common.isTouchDevice) return;
          gsap.to(target, {
            color: red,
            duration: 0,
            ease: "jet",
          });
        });
        target.addEventListener("mouseleave", () => {
          gsap.to(target, {
            color: black,
            duration: 0,
            ease: "jet",
          });
        });
        target.addEventListener("click", () => {
          const start = () => {
            gsap.to(target, {
              color: red,
              duration: pulseDuration,
              ease: "pulse",
            });
          };
          gsap.to(target, {
            color: black,
            duration: 0,
            overwrite: true,
            onComplete: start,
          });
        });
      });
    };

    setBunner();
    setList();
  }
}

export default new MediaPage();
