import { fadeInBorder } from "../../utils/animation/borderAnimation";
import { inviewWideList } from "../../utils/animation/inViewAnimation";
import { fadeInTileAnimations } from "../../utils/animation/tileAnimation";
import { wideListMouseAnmation } from "../../utils/animation/wideListAnimation";
import FaqList from "../FaqList";
import TopPage from "./TopPgae";
import PageFooter from "../PageFooter";
import { sideArrowButtonAnimation } from "../../utils/animation/sideArrowButtonAnimation";
import Common from "../Common";
import { gsap } from "gsap";
import { pulseDuration } from "../../utils/animation/pulseAnimation";

class TrainingIndexPage {
  constructor() {}
  init() {
    FaqList.init();
    inviewWideList(".location_body");
    wideListMouseAnmation(".location_wideList.--tokyo");
    wideListMouseAnmation(".location_wideList.--osaka");
    TopPage.setLocationAnimation();
    this.setIntro();
    this.setReview();
    PageFooter.init();
    sideArrowButtonAnimation(
      ".priceSection_button",
      ".priceSection_button_bg",
      ".priceSection_button_triangle.--right",
      ".priceSection_button_triangle.--left",
      ".priceSection_button_text",
      300,
      100
    );
  }
  setIntro() {
    const inView = (anchor, texts, borders) => {
      const options = {
        root: null,
        rootMargin: "-20% 0px",
        threshold: 0,
      };
      const start = (entry) => {
        if (entry[0].isIntersecting) {
          fadeInTileAnimations(texts);
          borders.forEach((border) => {
            fadeInBorder(border);
          });
          observer.unobserve(entry[0].target);
        }
      };
      const observer = new IntersectionObserver(start, options);
      observer.observe(anchor);
    };

    const intro = document.querySelector(".intro");
    const introHeadLineTexts = document.querySelectorAll(
      ".intro_headline_text_span"
    );
    const introBorders = document.querySelectorAll(".intro .redLine_container");
    inView(intro, introHeadLineTexts, introBorders);
  }
  setReview() {
    const anchor = document.querySelector(".review_anchor");
    const anchorTextPc = document.querySelector(".review_anchor .--pc");
    const anchorTextSp = document.querySelector(".review_anchor .--sp");
    anchor.addEventListener("mouseenter", () => {
      if (Common.isTouchDevice) return;
      const startPc = () => {
        gsap.to(anchorTextPc, {
          opacity: 1,
          duration: pulseDuration,
          ease: "pulse",
        });
      };
      gsap.to(anchorTextPc, {
        opacity: 0,
        duration: 0,
        overwrite: true,
        onComplete: startPc,
      });
      const startSp = () => {
        gsap.to(anchorTextSp, {
          opacity: 1,
          duration: pulseDuration,
          ease: "pulse",
        });
      };
      gsap.to(anchorTextSp, {
        opacity: 0,
        duration: 0,
        overwrite: true,
        onComplete: startSp,
      });
    });
    anchor.addEventListener("click", () => {
      const startPc = () => {
        gsap.to(anchorTextPc, {
          opacity: 1,
          duration: pulseDuration,
          ease: "pulse",
        });
      };
      gsap.to(anchorTextPc, {
        opacity: 0,
        duration: 0,
        overwrite: true,
        onComplete: startPc,
      });
      const startSp = () => {
        gsap.to(anchorTextSp, {
          opacity: 1,
          duration: pulseDuration,
          ease: "pulse",
        });
      };
      gsap.to(anchorTextSp, {
        opacity: 0,
        duration: 0,
        overwrite: true,
        onComplete: startSp,
      });
    });
  }
}

export default new TrainingIndexPage();
